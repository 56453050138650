import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactModal from 'react-modal';
import { ASSETS } from '../asset';
import { useRecoilValue } from 'recoil';
import { translatedText } from '../recoil/recoil';




const EventModal = ({ state, closeFuc }) => {

    const [modalWidth, setModalWidth] = useState(window.innerWidth);
    const textData = useRecoilValue(translatedText);

    window.addEventListener('resize', () => {
        setModalWidth(window.innerWidth);
    })

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            zIndex: 999,
            transform: 'translate(-50%, -50%)',
            width: modalWidth > 1076 ? "40%" : "90%",
            height: "40%",
            borderRadius: "20px"
        },
        overlay: { zIndex: 1000, background: "rgba(0,0,0,0.3)" }
    };

    const modalContent = {
        "nonexistent_wallet_address": {
            header: textData['51'](),
            text: textData['52']()
        },
        "all_check": {
            header: textData['53'](),
            text: textData['54']()
        },
        "final_auth_check": {
            header: textData['55'](),
            text: textData['56']()
        },
        "not_email": {
            header: textData['57'](),
            text: textData['58']()
        },
        "not_wallet_address": {
            header: textData['59'](),
            text: textData['60']()
        },
        "duplicate_auth": {
            header: textData['61'](),
            text: textData['62']()
        },
        "failed_auth": {
            header: textData['63'](),
            text: textData['64']()
        },
        "failed_email": {
            header: textData['65'](),
            text: textData['66']()
        },
        "ended": {
            header: '',
            text: textData['75']()
        },
        "policy": {
            header: textData['61'](),
            text: textData['76']()
        },
        "not_twitter": {
            header: textData['61'](),
            text: "트위터 로그인을 먼저 진행 해주세요!"
        }
    }

    if (!state) return (<></>)

    return (
        <ReactModal
            isOpen={state !== null}
            style={customStyles}
            ariaHideApp={false}
        >
            <div className='event_modal_header'>
                <div>

                </div>
                <div>
                    <h1>{modalContent[state].header}</h1>
                </div>
                <div>
                    <button
                        onClick={closeFuc}
                        style={{ zIndex: 999 }}
                    >
                        <img src={ASSETS.close} />
                    </button>
                </div>
            </div>
            <div className='event_modal_body'>
                <p>
                    {modalContent[state].text}
                </p>
            </div>
            {/* <div className='event_modal_footer'>
                <Button>
                </Button>
                <Button></Button>
            </div> */}
        </ReactModal>
    )
}

export default EventModal