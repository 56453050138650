import React from 'react'

const RouletteBottom = () => {
    return (
        <svg width="340" height="340" viewBox="0 0 4058 3476" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2028.9 0L4010.8 3432.75H47L2028.9 0Z" fill="#6610F2" />
            <path d="M0 2842C0 2814.39 22.3858 2792 50 2792H4008C4035.61 2792 4058 2814.39 4058 2842V3426C4058 3453.61 4035.61 3476 4008 3476H50.0001C22.3858 3476 0 3453.61 0 3426V2842Z" fill="#6F42C1" />
        </svg>

    )
}

export default RouletteBottom