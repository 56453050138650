import React, { useEffect, useRef } from 'react'
import EmailAutoComplete from 'react-autocomplete-email';
import { useRecoilValue } from 'recoil';
import { translatedText } from '../../recoil/recoil';
import { Button, Dropdown } from 'react-bootstrap';
import { countryNumber } from '../../language/language';
import styles from './input.module.css';
import { useDisconnect } from 'wagmi';
import { getAuth, signInWithPopup, TwitterAuthProvider } from 'firebase/auth'
import { ASSETS } from '../../asset';
// import TelegramLoginButton from 'react-telegram-login';
import TelegramLoginButton from 'telegram-login-button';


const Input = ({
    label,
    value,
    setValue,
    setCountryCode,
    countryCode,
    postNumberState,
    onClick,
    signOut,
    check,
    setCheck,
    modalOpen,
    authStatus
}) => {
    const emailAutoCompleteRef = useRef();
    const textData = useRecoilValue(translatedText);
    const customDomains = [
        'gmail.com',
        'naver.com',
        'daum.net',
        'kakao.com',
        'yahoo.com',
        'outlook.com',
        'hotmail.com',
        'aol.com',
        'icloud.com',
        'hanmail.net',
        'ymail.com',
        'live.com',
        'protonmail.com',
        'mail.com',
        'gmx.com',
        'qq.com'
    ]


    if (label === 'Email') {
        return (
            <div className='event_input_box'>
                <p>{label} <span>*</span></p>
                <EmailAutoComplete className="email_wrap" ref={emailAutoCompleteRef} onCompletion={val => setValue(val)} domains={customDomains}>
                    <input
                        onKeyDown={(e) => emailAutoCompleteRef.current.check(e)}
                        value={value}
                        onChange={e => {
                            setValue(e.target.value.replace(' ', ''))
                            emailAutoCompleteRef.current.change(e);
                        }}
                        placeholder={textData['69']()}
                        disabled={check}
                    />
                </EmailAutoComplete>

            </div>
        )
    }

    if (label === 'Telegram') {
        return (
            <div className='event_input_box'>
                <p>{label} <span>*</span></p>
                {
                    !check &&
                    <TelegramLoginButton
                        botName="TrustBridgeX_second_event_bot"
                        dataOnauth={res => onClick(res)}
                        className={styles.telegram_button}
                    />
                }
                {
                    check &&
                    <div className={styles.verify_check_wrap}>
                        <Button onClick={() => setValue()} variant={"success"} style={{ border: 'none', width: '30%' }}>
                            {textData['177']()}
                        </Button>
                    </div>
                }
            </div >
        )
    }


    if (label === 'Phone Number') {
        return (
            <div className='event_input_box'>
                <p>{label} <span>*</span></p>
                <div className='event_phone_box' style={{ overflow: postNumberState && 'hidden' }}>
                    <Dropdown className='dropdown_wrap'>
                        <Dropdown.Toggle variant={"dark"} id="dropdown-basic" disabled={postNumberState}>
                            {/* <img src={filterLangData.icon} className="country_img" /> */}
                            <p>{countryCode[0]}</p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='country_dropdown'>
                            {
                                countryNumber?.map((item, idx) => (
                                    <Dropdown.Item
                                        key={idx}
                                        onClick={() => setCountryCode([item.enCountryCode, `${item.enCountryCode} +${item.CountryNum}`, item.CountryNum])}
                                        className="droplist_style"
                                    >
                                        <p>{item.enCountryCode} +{item.CountryNum}</p>
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <input
                        style={{ backgroundColor: postNumberState && 'rgba(0,0,0,0.2)' }}
                        disabled={postNumberState}
                        id='phone_input'
                        value={value}
                        onChange={e => setValue(e.target.value.replace(/[^0-9]/g, ''))}
                        placeholder={textData['71']()}
                    />
                </div>
            </div>
        )
    }

    if (label === 'Wallet Address') {
        return (
            <div className='event_input_box'>
                <p>{label} <span>*</span></p>
                {
                    // !address
                    // ? <button onClick={() => modalOpen()} id="connect_button">Connect Wallet</button>
                    <>
                        {/* <w3m-account-button /> */}
                        {/* <button onClick={() => modalOpen({ view: 'Networks' })} id="disconnect_button">{address}</button> */}
                        {/* <button onClick={() => modalOpen({ view: "Account" })} id="disconnect_button"></button> */}
                        <w3m-button loadingLabel='Connecting' />

                    </>
                }
                {/* <input
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder={textData['70']()}
            /> */}
            </div >
        )
    }

    if (label === 'Twitter') {
        return (
            <div className='event_input_box'>
                <p>{label} <span>*</span></p>
                {
                    authStatus && value?.length > 0
                        ?
                        <button className={styles.twitter_button} onClick={signOut}>
                            <img src={value[3]} id={styles.profile_image} />
                            <p>{value[2]}</p>
                        </button>
                        :
                        <button onClick={onClick} className={styles.twitter_button}>
                            <img src={ASSETS.x_logo} />
                            <p>SignIn Twitter</p>
                        </button>
                }
            </div >
        )
    }

    return (
        <div className='event_input_box'>
            <p>{label}</p>
            <input
                value={value}
                onChange={e => {
                    setValue(e.target.value)
                }}
                // 언어팩 예정
                placeholder={textData['79'](label)}
            />
        </div>
    )
}

export default Input