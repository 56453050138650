// Snowflake.jsx
import React, { useState, useEffect } from 'react';
import styles from './snow.module.css';
import { ASSETS } from '../../asset';

const Snowflake = ({ id }) => {
    const [animationDelay, setAnimationDelay] = useState('0s');
    const [fontSize, setFontSize] = useState('10px');

    const generateSnowflake = () => {
        const newDelay = `${(Math.random() * 16).toFixed(2)}s`;
        const newFontSize = `${Math.floor(Math.random() * 10) + 10}px`;
        setAnimationDelay(newDelay);
        setFontSize(newFontSize);
    };

    useEffect(() => {
        generateSnowflake();
    }, []);

    const style = { animationDelay, fontSize };

    return (
        <>
            <p id={`item${id}`} style={style} className={styles.Snowflake}>
                <img src={ASSETS.snow} width={'30px'} height={'30px'} />
            </p>
        </>
    );
};

export const Snow = () => {
    const numFlakes = 40;

    const snowflakes = Array.from({ length: numFlakes }, (_, i) => <Snowflake key={i} id={i} />);

    return <div className={styles.Snow}>{snowflakes}</div>;
};

//************************************
export default Snow