import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { ASSETS } from '../asset';

const NotFound = () => {
  const history = useHistory();

  useEffect(() => {
    // history.replace('/')
  }, [])

  return (
    <div className='event_container' style={{ background: "linear-gradient(35deg, #4f1bc5, #7b17c5)", flexDirection: 'column' }}>
      <div
        className="hero-bottom-shape-two"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      >
        <div className="animated-shape-wrap">
          <div className="animated-shape-item"></div>
          <div className="animated-shape-item"></div>
          <div className="animated-shape-item"></div>
          <div className="animated-shape-item"></div>
          <div className="animated-shape-item"></div>
        </div>
      </div>
      <img src={ASSETS.main_logo} style={{ width: "40%" }} />
      <div style={{ marginTop: "150px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <p style={{ fontSize: 35, color: "#fff" }}>Page not found</p>
        <Button onClick={() => history.push('/')} style={{ width: "250px" }}>
          Go Home
        </Button>
      </div>
    </div>
  )
}

export default NotFound