import React, { useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";

import Theme1 from "../themes/theme1";
import Theme2 from "../themes/theme2";
import Theme3 from "../themes/theme3";
import Theme4 from "../themes/theme4";
import Theme5 from "../themes/theme5";
import Theme6 from "../themes/theme6";
import Theme7 from "../themes/theme7";
import Theme8 from "../themes/theme8";
import Theme9 from "../themes/theme9";
import Login from "../themes/login";
import SignUp from "../themes/signup";
import ResetPassword from "../themes/resetPassword";
import Faq from "../themes/faq";
import NotFound from "../themes/404";
import ComingSoon from "../themes/comingSoon";
import Team from "../themes/team";
import SingleTeam from "../themes/singleTeam";
import BlogDefault from "../themes/blogDefault";
import BlogNoSidebar from "../themes/blogNoSidebar";
import BlogLeftSidebar from "../themes/blogLeftSidebar";
import BlogRightSidebar from "../themes/blogRightSidebar";
import BlogSingleLeftSidebar from "../themes/blogSingleLeftSidebar";
import BlogSingleRightSidebar from "../themes/blogSingleRightSidebar";
import AboutUs from "../themes/aboutUs";
import ContactUs from "../themes/contactUs";
import { useRecoilState } from "recoil";
import { language } from "../recoil/recoil";
import DocumentMeta from "react-document-meta";
import Event from "../page/event/Event";
import EventPostSuccess from "../page/EventPostSuccess";
import RouteTracker from "./routeCheckTracker";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";

const Routes = () => {
  const history = useHistory();
  const [lang, setLang] = useRecoilState(language);
  const [metaData, setMetaData] = useState({});


  useEffect(() => {
    const storageLang = localStorage.getItem("lang");
  if (!storageLang) {
      localStorage.setItem('lang', lang);
      setLang(lang);
    } else {
      console.log(storageLang);
      setLang(storageLang);
    }
  }, [])

  // useEffect(() => {

  // const descriptionText = {
  //   "ko": "가장 손쉬운 암호화폐 거래 - Trust Bridge X",
  //   "en": 'The easiest cryptocurrency trading - Trust Bridge X',
  //   "cn": "最简单的加密货币交易 - Trust Bridge X",
  //   "jp": "最も簡単な仮想通貨取引 - Trust Bridge X",
  //   "indonesia": "Perdagangan mata uang kripto paling mudah - Trust Bridge X"
  // }+



  // setMetaData({
  //   title: 'Trust Bridge X'
  // })
  // }, [lang])

  return (
    <React.Fragment>
      <DocumentMeta {...metaData}>
        <Router history={history}>
          <RouteTracker />
          <Switch>
            {/* <Route exact path="/" component={Theme1} /> */}
            <Route exact path="/" component={Event} />
            {/* <Route exact path="/event_success" component={EventPostSuccess} /> */}
            {/* <Route exact path="/theme1" component={Theme1} />
          <Route exact path="/theme2" component={Theme2} />
          <Route exact path="/theme3" component={Theme3} />
          <Route exact path="/theme4" component={Theme4} />
          <Route exact path="/theme5" component={Theme5} />
          <Route exact path="/theme6" component={Theme6} />
          <Route exact path="/theme7" component={Theme7} />
          <Route exact path="/theme8" component={Theme8} />
          <Route exact path="/theme9" component={Theme9} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/comingSoon" component={ComingSoon} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/singleTeam" component={SingleTeam} />
          <Route exact path="/blogDefault" component={BlogDefault} />
          <Route exact path="/blogNoSidebar" component={BlogNoSidebar} />
          <Route exact path="/blogLeftSidebar" component={BlogLeftSidebar} />
          <Route exact path="/blogRightSidebar" component={BlogRightSidebar} />debar} />
          <Route exact path="/blogSingleRightSidebar" component={BlogSingleRightSidebar} />
          <Route exact path="/aboutUs" component={AboutUs} />
          <Route exact path="/contactUs" component={ContactUs} />
          <Route exact path="/blogSingleLeftSidebar" component={BlogSingleLeftSi */}
            <Route exact path="/*" component={NotFound} />
          </Switch>
        </Router>
      </DocumentMeta>
    </React.Fragment>
  );
}

export default Routes;