import React, { useEffect, useState } from 'react'
import { ASSETS } from '../asset'

const EventItemBox = () => {
    const [width, setWidth] = useState(null);

    window.addEventListener('resize', () => {
        setWidth(window.innerWidth);
    })


    return (
        <div className='event_box'>
            <img src={
                // require('../assets/banner_web.png')
                require('../assets/eml_banner.png')
            } style={{ width: width >= 1200 ? '450px' : '340px' }} />
        </div>
    )
}

export default EventItemBox