import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from '../../components/Header/header';
import { ASSETS } from '../../asset';
import EventItemBox from '../../components/EventItemBox';
import Input from '../../components/Input/Input';
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import EventModal from '../../components/EventModal';
import { useRecoilValue } from 'recoil';
import { translatedText } from '../../recoil/recoil';
import { countryNumber } from '../../language/language';
import { isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import Loading from '../../components/Loading';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import Marquee from 'react-fast-marquee';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerButton from '../../components/CustomerButton/CustomerButton';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styles from './event.module.css'
import Countdown from '../../components/FlipCountdown/Countdown';
import Roulette from '../../components/Roulette/Roulette';
import Snow from '../../components/Snow/Snow';
import useInterval from '../../hooks/useInteval';
import webBackImage from '../../assets/back_img_web.png'
import confetti from 'canvas-confetti';
import { useWindowSize } from 'react-use';
import ReactConfetti from 'react-confetti';


const Event = () => {
    const [email, setEmail] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const { width, height } = useWindowSize();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [modalState, setModalState] = useState('');
    const textData = useRecoilValue(translatedText);
    const [countryCode, setCountryCode] = useState([countryNumber[0].enCountryCode, `${countryNumber[0].enCountry} +${countryNumber[0].CountryNum}`, countryNumber[0].CountryNum]);
    const [loading, setLoading] = useState(true);
    const [policyCheck, setPolicyCheck] = useState(false);
    const [twitterToken, setTwitterToken] = useState([]); // [accessToken,secretAccessToken,displayName,displayImage]
    const [eventItem, setEventItem] = useState([]);
    const [authStatus, setAuthStatus] = useState(false);
    const [signOutModalState, setSignOutModalState] = useState(false);
    const [airdropUserList, setAirdropUserList] = useState([]);
    const [remainingQuantity, setRemainingQuantity] = useState(0);
    const [progressEvent, setProgressEvent] = useState(true);
    const history = useHistory();
    const [allCheck, setAllCheck] = useState(false);
    const [emailAuthNumber, setEmailAuthNumber] = useState('');
    const [emailAuthCheck, setEmailAuthCheck] = useState(false);
    const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);
    const [authNumber, setAuthNumber] = useState('');
    const [emailVerifyStatus, setEmailVerifyStatus] = useState(false);
    const [telegramOAuth, setTelegramOAuth] = useState(null);
    const [finalAuthCheck, setFinalAuthCheck] = useState(false);
    let regexEmail = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const [animaitonOn, setAnimationOn] = useState(false);
    const [openRouletteModal, setOpenRouletteModal] = useState(false);
    // const { setThemeVariables } = useWeb3ModalTheme();
    // const { address, isConnected } = useAccount();
    // const { chain } = useNetwork();
    // const { switchNetwork, chains } = useSwitchNetwork({
    // chainId: 1,
    // })

    // const seletedNetworkContract = () => {
    //     if (chain?.id === 17000) {
    //         return [holesky.token_address, holesky.token_abi]
    //     }

    //     if (chain?.id === 6) {
    //         return [goerli.token_address, goerli.token_abi]
    //     }

    //     return [holesky.token_address, holesky.token_abi]
    // }

    // const { write } = useContractWrite({
    //     address: seletedNetworkContract()[0],
    //     abi: seletedNetworkContract()[1],
    //     functionName: 'redeem',
    //     async onSuccess(data) {
    //         setLoading(false);
    //         // await onFinalSubmit(data.hash);
    //     }
    // });

    // useEffect(() => {
    //     if (chain?.id !== 1 && isConnected) {
    //         // console.log(chains[0].id)
    //         switchNetwork(chains[0].id);
    //     }
    // }, [chain])




    useEffect(() => {
        getTokenAllUser();

    }, [])

    useEffect(() => {
        if (openRouletteModal || !progressEvent) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.removeProperty('overflow');
        }
    }, [openRouletteModal, progressEvent])

    // useEffect(() => {
    //     // web3modal style
    //     setThemeVariables({
    //         '--w3m-z-index': 100000
    //     })
    // }, [])

    useEffect(() => {
        if (regexEmail.test(email) && phoneNumber && finalAuthCheck && policyCheck && emailVerifyStatus) {
            setAllCheck(true);
        } else {
            setAllCheck(false);
        }
    }, [email, phoneNumber, finalAuthCheck, countryCode, policyCheck])

    // 이벤트를 신청하고 토큰을 받아온 유저 중 EML을 많이 받아간 순서대로 최대 30명의 데이터를 반환
    const getTokenAllUser = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_airdrop_top_user_list`);

            console.log(data, '/event_second_airdrop_top_user_list 결과값');

            const { RETCODE, results } = data;

            if (RETCODE === "SUCCESS_EVENT_SECOND_AIRDROP_TOP_USER_LIST") {
                const { event_second_airdrop_top_user_list: userList, event_in_progress: progress, event_countdown } = results;

                if (!progress || event_countdown === 0) {
                    // alert(textData['75']());
                    setProgressEvent(false);
                    return history.push('/');
                }

                setAnimationOn(true);
                setRemainingQuantity(event_countdown);
                setAirdropUserList(userList);
            }
        } catch (err) {
            console.log(err);
            showToast('error');
        } finally {
            setLoading(false);
        }
    }

    // 토큰을 메타마스크에 자동으로 추가해주는 함수
    const addToken = async () => {
        try {
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: '0x03dDe9e5BB31ee40A471476e2FCcF75C67921062', // The address that the token is at.
                        symbol: 'EML', // A ticker symbol or shorthand, up to 5 chars.
                        decimals: 18, // The number of decimals in the token
                        image: 'https://s2.coinmarketcap.com/static/img/coins/128x128/26982.png', // A string url of the token logo
                    },
                },
            });

            if (wasAdded) {
                showToast('token_add', 'success');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
            if (
                error.message === "Cannot read properties of undefined (reading 'request')" // chrome
                || error.message === "undefined is not an object (evaluating 'window.ethereum.request')" // mobile
                || error.message === "window.ethereum is undefined" // firefox
            ) {
                return showToast('no_metamask')
            }

            console.dir(error);
        }
    }

    const clearState = () => {
        setEmail('');
        setWalletAddress('');
        setPhoneNumber('');
        setPhoneNumberCheck(false);
        setAuthNumber('');
        setEmailAuthNumber('');
        setTelegramOAuth(null);
        setEmailVerifyStatus(false)
        setTwitterToken([]);
        setFinalAuthCheck(false);
        setEmailAuthCheck(false);
        setPolicyCheck(false);
    }


    const telegramOAuthFuc = res => {
        console.log(res, 'Telegram OAuth');
        if (res) {
            showToast('telegram_success', 'success');
            setTelegramOAuth(res);
        }
    }

    const userInputList = [
        {
            title: "Email",
            value: email,
            setValue: setEmail,
            check: emailAuthCheck
        },
        {
            title: "Telegram",
            onClick: telegramOAuthFuc,
            check: telegramOAuth,
            setValue: () => setTelegramOAuth(null)
        },
        // {
        //     title: "Wallet Address",
        //     value: walletAddress,
        //     setValue: setWalletAddress,
        // },
        {
            title: "Phone Number",
            value: phoneNumber,
            setValue: setPhoneNumber,
            setCountryCode: setCountryCode,
            countryCode: countryCode
        },
    ]


    // 인증번호 발송 함수
    const postAuthNumber = async () => {
        if (!progressEvent) return;
        if (!phoneNumber) return;

        // const parseNumber = parsePhoneNumber(phoneNumber, countryCode[0]).number;
        const valid = isValidPhoneNumber(phoneNumber, countryCode[0]);
        const length = validatePhoneNumberLength(phoneNumber, countryCode[0]);

        // 네이버 sens 번호 요청 변경
        const parseNumber = parsePhoneNumber(phoneNumber, countryCode[0]).nationalNumber;

        const postNumber = `${countryCode[2]} ${parseNumber}`;

        if (length === undefined && valid) {
            try {
                setLoading(true);
                const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/sms_auth_send`, {
                    phone_number: postNumber,
                    type: 'event_second_airdrop'
                });

                console.log(data, '/sms_auth_send 결과값');

                if (data['RETCODE'] === "SUCCESS_THIS_PHONE_NUMBER_HAS_ALREADY_BEEN_JOINED") {
                    setModalState('duplicate_auth')
                }

                if (data['RETCODE'] === "SUCCESS_SMS_AUTH_SEND") {
                    showToast('send_auth_number', 'success');
                    setPhoneNumberCheck(true);
                }
            } catch (error) {
                showToast('error');
                console.error(error);
            } finally {
                setLoading(false);
            }
        } else {
            showToast('invalid_phone_number');
        }
    }

    // 인증번호 체크 함수
    const postAuthCheck = async () => {
        if (finalAuthCheck) return;

        const parseNumber = parsePhoneNumber(phoneNumber, countryCode[0]).number;

        console.log({
            phone_number: parseNumber,
            auth_number: Number(authNumber),
            type: 'event_second_airdrop'
        }, 'auth_verify')

        if (authNumber.length > 0) {
            try {
                setLoading(true);
                const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/sms_auth_verify`, {
                    phone_number: parseNumber,
                    auth_number: Number(authNumber),
                    type: 'event_second_airdrop'
                });

                console.log(data, '/sms_auth_verify 결과값');

                if (data['RETCODE'] === "SUCCESS_SMS_AUTH_VERIFY") {
                    return setFinalAuthCheck(true);
                }

                if (data['RETCODE'] === "SUCCESS_INVALID_AUTH_NUMBER") {
                    return setModalState('failed_auth');
                }
            } catch (error) {
                showToast('error');
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    }

    // 최종 전송 함수
    const onFinalSubmit = async () => {
        // const parseNumber = parsePhoneNumber(phoneNumber, countryCode[0]).number;
        // 네이버 sens 번호 요청 변경
        const parseNumber = parsePhoneNumber(phoneNumber, countryCode[0]).nationalNumber;
        const postNumber = `${countryCode[2]} ${parseNumber}`;

        try {

            console.log({
                phone_number: postNumber,
                // phone_number: phoneNumber,
                email: email,
                telegram_id: telegramOAuth.id,
                telegram_first_name: telegramOAuth?.first_name ?? null,
                telegram_last_name: telegramOAuth?.last_name ?? null,
                telegram_username: telegramOAuth?.username ?? null,
                telegram_auth_date: telegramOAuth.auth_date,
                telegram_hash: telegramOAuth.hash
                // wallet_address: address,
                // hash
            }, 'body')
            const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_airdrop_request`, {
                phone_number: postNumber,
                // phone_number: phoneNumber,
                email: email,
                telegram_id: telegramOAuth.id,
                telegram_first_name: telegramOAuth?.first_name ?? null,
                telegram_last_name: telegramOAuth?.last_name ?? null,
                telegram_username: telegramOAuth?.username ?? null,
                telegram_auth_date: telegramOAuth.auth_date,
                telegram_hash: telegramOAuth.hash
                // wallet_address: address,
                // hash
            });



            console.log(data, 'event_second_airdrop_request')

            // 전송 성공 시
            if (data['RETCODE'] === "SUCCESS_EVENT_SECOND_AIRDROP") {
                // const drawn = data.results['amount_drawn'];
                // 20231207 로직변경 (받는값 추가 되어야함);
                console.log(data, '성공값');
                setEventItem(data.results);
                // Visible Modal
                // await getTokenAllUser();
                return setOpenRouletteModal(true);
            }

            // 중복 된 신청 시
            if (data['RETCODE'] === 'SUCCESS_DUPLICATE_AUTH_INFO') {
                return setModalState('duplicate_auth');
            }

            // 요청 실패 시
            if (data['RETCODE'] === 'FAIL_EVENT_SECOND_AIRDROP') {
                return showToast('error');
            }

            // 이벤트가 종료 되었을 시 (준비된 수량 전부 소진)
            if (data['RETCODE'] === "SUCCESS_EVENT_SECOND_AIRDROP_ENDED") {
                setLoading(false);
                setProgressEvent(false);
            }
        } catch (error) {
            showToast('error');
            console.error(error);
        } finally {
            setLoading(false);
            // state 초기화
            clearState();
        }
    }

    // 0 ETH 전송 함수 (2023.11.14 기획 변경 후 주석)
    // const sendGasFee = async (receive_address) => {
    //     try {
    //         const sign = await signMessage('송금하실?');

    //         if (chain.id === 1) {
    //             await sendTransactionAsync({ to: receive_address });
    //         } else {
    //             write({
    //                 args: [
    //                     receive_address,
    //                     parseEther('0')
    //                 ]
    //             })
    //         }

    //         // console.log(data);
    //     } catch (error) {
    //         setLoading(false);
    //         console.error(error);
    //     }
    // }

    // 최종 전송 전 유저 중복체크
    const checkInvalidUser = async () => {
        try {
            if (!regexEmail.test(email)) return showToast('failed_email');
            if (!email) return showToast('not_email');
            if (!telegramOAuth) return showToast('invaild_telegram')
            // if (!address) return showToast('not_wallet_address');
            // if (isConnected && chain?.id !== 1) return showToast('change_network')
            if (!finalAuthCheck) return showToast('final_auth_check')
            if (!allCheck) return showToast('all_check');
            if (!policyCheck) return showToast('policy');

            const parseNumber = parsePhoneNumber(phoneNumber, countryCode[0]).number;
            setLoading(true);

            const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_airdrop_verify`, {
                // wallet_address: address,
                telegram_id: telegramOAuth.id,
                phone_number: parseNumber,
                email
            })

            console.log(data, '/event_first_airdrop_verify 결과값');

            // 중복 된 항목이 하나도 없을 시 (success)
            if (data['RETCODE'] === "SUCCESS_EVENT_SECOND_AIRDROP_VERIFY_SUCCESS") {
                console.log('들어옴');
                return await onFinalSubmit();
            }

            // 이벤트가 종료 되었을 시
            if (data['RETCODE'] === "SUCCESS_EVENT_SECOND_AIRDROP_VERIFY_ENDED") {
                setLoading(false);
                return setModalState('ended');
            }

            // 이미 한번 신청 할 내역이 있을 시
            if (data['RETCODE'] === 'SUCCESS_DUPLICATE_AUTH_INFO') {
                setLoading(false);
                return setModalState('duplicate_auth')
            }

        } catch (error) {
            showToast('error');
            console.error(error);
            setLoading(false);
        }
    }

    // toast
    const showToast = (textType, type = 'error') => {
        console.log(textType);
        const text = {
            "invaild_telegram": () => { return textData['170']() },
            "nonexistent_wallet_address": () => { return textData['52']() },
            "all_check": () => { return textData['54']() },
            "final_auth_check": () => { return textData['56']() },
            "not_email": () => { return textData['58']() },
            "not_wallet_address": () => { return textData['60']() },
            "duplicate_auth": () => { return textData['62']() },
            "failed_auth": () => { return textData['64']() },
            "failed_email": () => { return textData['66']() },
            "ended": () => { return textData['75']() },
            "policy": () => { return textData['76']() },
            "not_twitter": () => { return textData['118']() },
            "error": () => { return textData['119']() },
            "twitter_error": () => { return textData['120']() },
            "send_auth_number": () => { return textData['121']() },
            "twitter_signin": () => { return textData['122']() },
            "no_metamask": () => { return textData['123']() },
            "token_add": () => { return textData['124']() },
            "invalid_phone_number": () => { return textData['117']() },
            "change_network": () => { return textData['161']() },
            "duplication": () => { return textData['143']() },
            "send_email": () => { return textData['171']() },
            "invalid_email_auth": () => { return textData['172']() },
            "email_auth_success": () => { return textData['173']() },
            "telegram_success": () => { return textData['178']() }
        }

        toast(text[textType], {
            autoClose: true,
            theme: 'dark',
            type,
            position: 'top-right',
            draggable: true
        })
    }

    // 로직변경 (20231207 컨트랙에서 리딤)
    const getDrawnToken = async () => {
        // const sign = await signMessage('송금하실?');
        // setSuccessModalVisible(false);
        // setLoading(true);
        // write({
        //     args: [
        //         "0xCD8BFf084386D5E30F8c781967FBcb78eD622349",
        //         parseEther('0')
        //     ]
        // })
    }

    const sendEmailVerifyCode = async () => {
        if (!regexEmail.test(email)) return showToast('failed_email');
        if (emailVerifyStatus) return;
        try {

            if (!emailAuthCheck) {
                setLoading(true);
                const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_email_auth_send`, {
                    email
                });

                console.log(data, '/event_second_email_auth_send');

                const { results, RETCODE } = data;

                if (RETCODE === 'EVENT_SECOND_EMAIL_AUTH_SEND') {
                    showToast('failed_email');
                }

                if (RETCODE === 'SUCCESS_THIS_EMAIL_HAS_ALREADY_BEEN_JOINED') {
                    showToast('duplication');
                }

                if (RETCODE === 'SUCCESS_EVENT_SECOND_EMAIL_AUTH_SEND') {
                    showToast('send_email', 'success');
                    setEmailAuthCheck(true);
                }


                console.log(RETCODE, '20240117 email send');

            }

            if (emailAuthCheck) {
                if (emailAuthNumber.length === 0) return;
                setLoading(true);
                const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_email_auth_verify`, {
                    email,
                    auth_number: emailAuthNumber
                });

                const { RETCODE } = data;

                console.log(data, emailAuthNumber)

                if (RETCODE === 'EVENT_SECOND_EMAIL_AUTH_VERIFY') {
                    showToast('failed_email');
                }

                if (RETCODE === 'SUCCESS_INVALID_AUTH_NUMBER') {
                    showToast('invalid_email_auth');
                }

                if (RETCODE === 'SUCCESS_EVENT_SECOMD_EMAIL_AUTH_VERIFY') {
                    showToast('email_auth_success', 'success');
                    setEmailVerifyStatus(true);
                }
                // Email 인증 엔드포인트
                console.log('성공!');
            }
        } catch (error) {
            console.error(error);
            showToast('error');
        } finally {
            setLoading(false);
        }
    }

    useInterval(async () => {
        try {
            // 10초마다 갱신되는 데이터
            const { data } = await axios.post(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_airdrop_top_user_list`);

            console.log(data, 'event_second_airdrop_top_user_list 222');

            if (data.RETCODE === "SUCCESS_EVENT_FIRST_AIRDROP_TOP_USER_LIST") {
                const { event_countdown, event_in_progress } = data.results;

                if (!event_in_progress) return;

                setRemainingQuantity(event_countdown);
            }


        } catch (err) {
            console.error(err);
        }
    }, progressEvent ? 10000 : null)

    return (
        <>

            {/* <div className='event_container' style={{ background: "linear-gradient(35deg, #BF091B, #E2494F)" }}> */}
            <div className='event_container' style={{
                // background: "linear-gradient(35deg, #BF091B, #E2494F)",
                background: "linear-gradient(to bottom, #212121 0%, #090909 100%)",
                // background: "linear-gradient(to bottom, #212121 0%, #090909 100%)",
                overflowX: 'hidden'
            }}>
                {
                    !progressEvent &&
                    <div className={styles.ended_event_wrap}>
                        <img src={ASSETS.main_logo} />
                        <h1>{textData['75']()}</h1>
                        <p>{textData['179']()}</p>
                        <Button variant='success' onClick={() => window.open('https://trustbridgex.io')}>
                            Go to TrustBridgeX
                        </Button>
                    </div>
                }
                <ReactConfetti
                    width={width - 20}
                    numberOfPieces={window.innerWidth > 1074 ? 100 : 40}
                    wind={0}
                    run={animaitonOn}
                    height={
                        document.body.scrollHeight > window.innerHeight
                            ? document.body.scrollHeight
                            : window.innerHeight} style={{ overflow: 'hidden', zIndex: 1 }}
                />


                {/* <div className='event_container' style={{ background: "linear-gradient(35deg, #4f1bc5, #7b17c5)" }}> */}
                <Roulette
                    drawn={eventItem}
                    setIsOpen={setOpenRouletteModal}
                    isOpen={openRouletteModal}
                    getTokenAllUser={getTokenAllUser}
                />
                {/* <Snow /> */}
                {/* Loading */}

                {
                    loading && <Loading />
                }
                {/* Modal */}
                <EventModal state={modalState} closeFuc={() => setModalState(null)} />
                {/* Modal end */}
                <Header event />
                <Container style={{ position: "relative", zIndex: 999, marginTop: '25px', maxWidth: '1000px' }}>
                    <div
                        className="hero-bottom-shape-two"
                        style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom center",
                        }}
                    >
                        <div className="animated-shape-wrap">
                            <div className="animated-shape-item"></div>
                            <div className="animated-shape-item"></div>
                            <div className="animated-shape-item"></div>
                            <div className="animated-shape-item"></div>
                            <div className="animated-shape-item"></div>
                        </div>
                    </div>
                    <Row className="event_wrap">
                        <Marquee
                            className='marquee_container'
                            style={{ background: airdropUserList?.length === 0 && 'transparent' }}
                        >
                            {
                                airdropUserList?.map(({ date_created, email = '', amount_drawn }, index) => (
                                    <div className='marquee_item' key={index}>
                                        {/* <p>{address.slice(0, 8)}... 님께서 {amount} EML 당첨</p> */}
                                        <p>{moment(date_created).format('YY.MM.DD')} {email.slice(0, 5)}... {amount_drawn} EML</p>
                                    </div>
                                ))
                            }
                        </Marquee>
                        <Row>
                            <Col xs={12} lg={12} style={{ height: "fit-content", marginBottom: '30px' }}>
                                <div className='event_main_text_box'>
                                    <img src={ASSETS.main_logo} alt="Logo" />
                                    <h1>{textData['41']()}</h1>
                                </div>
                                <div className='event_box_container'>
                                    <div className={styles.countdown_box}>
                                        <div>
                                            <p>{textData['162']()}</p>
                                            <div>
                                                <Countdown value={remainingQuantity} />
                                                {/* <p>EML</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <EventItemBox />

                                    </div>
                                    {/* <EventItemBox />
                                        <EventItemBox /> */}
                                </div>
                                <div className='event_warning'>
                                    {/* 문구전달 이후 수정 해야 할 곳 Start */}
                                    <p>{textData['127']()}</p>
                                    <ul>
                                        <li>
                                            - {textData['128']()}
                                        </li>
                                    </ul>
                                    {/* 문구전달 이후 수정 해야 할 곳 End */}
                                </div>
                                <div className='event_warning'>
                                    {/* 문구전달 이후 수정 해야 할 곳 Start */}
                                    <p>{textData['129']()}</p>
                                    <ul>
                                        <li>
                                            - {textData['130']()}
                                        </li>
                                        <li>
                                            - {textData['180']()}
                                        </li>
                                    </ul>
                                    {/* 문구전달 이후 수정 해야 할 곳 End */}
                                </div>
                                <div className='event_warning'>
                                    {/* 문구전달 이후 수정 해야 할 곳 Start */}
                                    <p>{textData['131']()}</p>
                                    <ul>
                                        <li>
                                            - {textData['132']()}
                                        </li>
                                    </ul>
                                    {/* 문구전달 이후 수정 해야 할 곳 End */}
                                </div>
                                <div className='event_warning'>
                                    {/* 문구전달 이후 수정 해야 할 곳 Start */}
                                    <p>{textData['163']()}</p>
                                    <ul>
                                        <li>
                                            - {textData['164']()}
                                        </li>
                                    </ul>
                                    {/* 문구전달 이후 수정 해야 할 곳 End */}
                                </div>
                                {/* <div className='event_warning'> */}
                                {/* 문구전달 이후 수정 해야 할 곳 Start */}
                                {/* <p>{textData['134']()}</p> */}
                                {/* <ul>
                                        <li>
                                            - {textData['135']()}
                                        </li>
                                    </ul> */}
                                {/* 문구전달 이후 수정 해야 할 곳 End */}
                                {/* </div> */}
                                <div className='event_warning'>
                                    {/* 문구전달 이후 수정 해야 할 곳 Start */}
                                    <p>{textData['136']()}</p>
                                    <ul>
                                        <li>
                                            - {textData['137']()}
                                        </li>
                                        <li>
                                            - {textData['169']()}
                                        </li>
                                    </ul>
                                    {/* 문구전달 이후 수정 해야 할 곳 End */}
                                </div>
                                <div className='event_warning'>
                                    <Accordion defaultActiveKey={'1'}>
                                        {/* 문구전달 이후 수정 해야 할 곳 Start */}
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header
                                                id="guide_line_header"
                                                style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "transparent",

                                                }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{ color: "#fff", marginBottom: 0, fontSize: '15px' }}>
                                                        {textData['138']()}
                                                    </p>
                                                    <img src={ASSETS.down} style={{ width: '20px', marginLeft: '15px', height: 'auto' }} />
                                                </div>
                                            </Accordion.Header >
                                            <Accordion.Body>
                                                <ul id="guide_list">
                                                    <li>
                                                        - {textData['139']()}
                                                    </li>
                                                    <li>
                                                        - {textData['140']()}
                                                    </li>
                                                    <li>
                                                        - {textData['141']()}
                                                    </li>
                                                    <li>
                                                        - {textData['142']()}
                                                    </li>
                                                    <li>
                                                        - {textData['143']()}
                                                    </li>
                                                    <li>
                                                        - {textData['144']()}
                                                    </li>
                                                    <li>
                                                        - {textData['145']()}
                                                    </li>
                                                    <li>
                                                        - {textData['146']()}
                                                    </li>
                                                    <li>
                                                        - {textData['148']()}
                                                    </li>
                                                    <li>
                                                        - {textData['149']()}
                                                    </li>
                                                    <li>
                                                        - {textData['150']()}
                                                    </li>
                                                    <li>
                                                        - {textData['151']()}
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                {/* 문구전달 이후 수정 해야 할 곳 End */}
                            </Col>
                            <Col xs={12} lg={12} className='event_form'>
                                {
                                    userInputList.map(item => (
                                        <>
                                            <Input
                                                key={item.title}
                                                label={item.title}
                                                value={item.value}
                                                countryCode={countryCode}
                                                setCountryCode={setCountryCode}
                                                setValue={item.setValue}
                                                postNumberState={phoneNumberCheck}
                                                signOut={() => setSignOutModalState(true)}
                                                onClick={item.onClick}
                                                authStatus={authStatus}
                                                modalOpen={open}
                                                check={item.check}
                                            />
                                            {
                                                item.title === 'Email' &&
                                                <>
                                                    <div className={styles.email_verify_button_wrap}>
                                                        {
                                                            emailAuthCheck &&
                                                            <input
                                                                value={emailAuthNumber}
                                                                onChange={e => setEmailAuthNumber(e.target.value.trim(''))}
                                                                placeholder={textData['176']()}
                                                                disabled={emailVerifyStatus}
                                                            />
                                                        }
                                                        <Button
                                                            onClick={sendEmailVerifyCode}
                                                            id={styles.verify_button}
                                                            variant={
                                                                emailVerifyStatus ?
                                                                    'success'
                                                                    :
                                                                    emailAuthNumber?.length > 0 ? "warning" : 'secondary'
                                                            }
                                                        >
                                                            {/* 문구추가 이메일인증 */}
                                                            {emailAuthCheck ? `${textData['175']()}` : `${textData['174']()}`}
                                                        </Button>
                                                    </div>
                                                    {
                                                        emailAuthCheck &&
                                                        <div className={styles.email_verify_button_wrap}>
                                                            <span>
                                                                {textData['72']()}
                                                            </span>
                                                            <Button
                                                                variant={"warning"}
                                                                onClick={() => { setEmailVerifyStatus(false); setEmail(''); setEmailAuthNumber(''); setEmailAuthCheck(false) }}
                                                            >
                                                                <p>
                                                                    {/* 초기화 버튼 */}
                                                                    {textData['73']()}
                                                                </p>
                                                            </Button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    ))
                                }
                                <div className='phone_check_button_box'>
                                    {
                                        phoneNumberCheck
                                            ?
                                            <div>
                                                <div>
                                                    <input
                                                        value={authNumber}
                                                        onChange={e => setAuthNumber(e.target.value.trim(''))}
                                                        placeholder={textData['47']()}
                                                        disabled={finalAuthCheck}
                                                    />
                                                    <Button
                                                        variant={
                                                            finalAuthCheck
                                                                ? 'success'
                                                                : authNumber?.length > 0 ? "warning" : 'secondary'
                                                        }
                                                        onClick={postAuthCheck}
                                                    >
                                                        <p>
                                                            {textData['48']()}
                                                        </p>
                                                    </Button>
                                                </div>
                                                <div>
                                                    <span>
                                                        {textData['72']()}
                                                    </span>
                                                    <Button
                                                        variant={"warning"}
                                                        onClick={() => { setPhoneNumberCheck(false); setPhoneNumber(''); setAuthNumber(''); setFinalAuthCheck(false) }}
                                                    >
                                                        <p>
                                                            {textData['73']()}
                                                        </p>
                                                    </Button>
                                                </div>
                                            </div>
                                            :
                                            <Button variant={
                                                validatePhoneNumberLength(phoneNumber, countryCode[0]) === undefined ? "warning" : 'secondary'}
                                                onClick={postAuthNumber}
                                            >
                                                <p>
                                                    {textData['49']()}
                                                </p>
                                            </Button>
                                    }
                                </div>
                                <PrivacyPolicy
                                    check={policyCheck}
                                    setCheck={setPolicyCheck}
                                />
                                <div className='event_button_box'>
                                    <Button
                                        variant={
                                            allCheck
                                                ? "success"
                                                : 'secondary'
                                        }
                                        onClick={() => checkInvalidUser()}
                                    >
                                        <p>
                                            {textData['50']()}
                                        </p>
                                    </Button>
                                </div>
                                {/* <div className='add_token_box'>
                                    <Button
                                        variant='warning'
                                        onClick={addToken}
                                    >
                                        <p>
                                            {textData['125']()}
                                        </p>
                                    </Button>
                                </div> */}
                            </Col>
                        </Row>
                    </Row>
                    <div className={styles.event_bottom_button_wrap}>
                        <button onClick={() => window.open(`https://emlprotocol.io/`)}>
                            <img src={ASSETS.eml} />
                        </button>
                        <button onClick={() => window.open(`https://twitter.com/EMLprotocol`)}>
                            <img src={ASSETS.x_logo_black} />
                        </button>
                        <button onClick={() => window.open(`https://t.me/EMLprotocol`)}>
                            <img src={ASSETS.telegram} />
                        </button>
                    </div>
                </Container >

                <CustomerButton
                    link={"https://t.me/+953lQTDqFwdmOGE1"}
                />

            </div >


            <ToastContainer />
        </>
    );
}

export default Event;