import React from 'react'
import Lottie from 'react-lottie-player'

const Loading = () => {
    return (
        <div className='loading_ui'>
            <Lottie
                animationData={require('../assets/loading.json')}
                play
                loop
                style={{ width: "30%" }}
            />
        </div>
    )
}

export default Loading