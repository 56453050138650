import React, { useEffect, useState, useRef } from 'react';
import { classNames } from '../../libs/index.js';
import './RouletteWheel.css';

// winningBet = 당첨될 string number
// wheelNumbers = 바탕이 될 string number의 배열

export const RouletteWheel = ({
  start,
  winningBet,
  onSpinningEnd,
  withAnimation,
  addRest,
  wheelNumbers
}) => {
  // const [wheelNumbers, setWheelNumbers] = useState([]);

  const innerRef = useRef(null);

  useEffect(() => {

  }, []);

  useEffect(() => {
    const currentInnerRef = innerRef.current;

    if (winningBet === '-1' || currentInnerRef === null || start === false) {
      return;
    }

    if (addRest === true) {
      currentInnerRef.classList.remove('rest');
    }

    currentInnerRef.removeAttribute('data-spintoindex');

    const betIndex = wheelNumbers.indexOf(winningBet);

    setTimeout(() => {
      currentInnerRef.setAttribute('data-spintoindex', `${betIndex}`);

      setTimeout(() => {
        if (addRest === true) {
          currentInnerRef.classList.add('rest');
        }

        onSpinningEnd();
      }, 9000);
    }, 100);
    // we're ignoring only the onSpinningEnd onSpinningEnd dep
    // eslint-disable-next-line
  }, [winningBet, start]);

  return (
    <div className="roulette-wheel-container">
      <div
        className={classNames('roulette-wheel-plate', {
          'with-animation': withAnimation,
        })}
      >
        <ul className="roulette-wheel-inner" ref={innerRef}>
          {wheelNumbers.map((number) => (
            <li
              key={`wheel-${number}`}
              data-bet={number}
              className="roulette-wheel-bet-number"
            >
              <label htmlFor={`wheel-pit-${number}`}>
                <input
                  type="radio"
                  name="pit"
                  id={`wheel-pit-${number}`}
                  defaultValue={number}
                />
                <span className="roulette-wheel-pit">{number}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

RouletteWheel.defaultProps = {
  onSpinningEnd: () => undefined,
  withAnimation: true,
  addRest: true,
};
