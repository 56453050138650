import React, { useEffect } from "react";
import Routes from "./routers";
import { RecoilRoot } from "recoil";

// create store
// const store = await createStore(
//   Apdash,
//   composeWithDevTools()
// );


// const projectId = "0889cbb6118a0c6780b71e0ca1689efd";

// // 2. Create wagmiConfig
// const { chains, publicClient } = configureChains(
//   [mainnet],
//   [walletConnectProvider({ projectId }), publicProvider()]
// )

// // 3. Create modal
// const metadata = {
//   name: 'TrustBridgeX',
//   description: 'TrustBridgeX',
//   url: 'https://event.trustbridgex.io',
//   icons: [ASSETS.main_logo_full],
//   verifyUrl: "https://event.trustbridgex.io"
// }

// const wagmiConfig = createConfig({
//   connectors: [
//     new WalletConnectConnector({
//       chains,
//       options: {
//         relayUrl: 'wss://relay.walletconnect.com',
//         projectId,
//         showQrModal: false,
//         metadata
//       }
//     }),
//     new EIP6963Connector({ chains }),
//     new InjectedConnector({ chains, options: { shimDisconnect: true } }),
//     new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
//   ],
//   publicClient
// })

// createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  useEffect(() => {
    // 빌드 이후 콘솔 전부 지우기
    if (process.env.NODE_ENV === "production") {
      console = window.console || {};
      console.log = function no_console() { };
      console.warn = function no_console() { };
      console.error = function () { };
    }
  }, [])

  return (
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  );
}

export default App;