import React from 'react'
import styles from '../components/PrivacyPolicy/privacyPolicy.module.css'
import { useRecoilValue } from 'recoil'
import { translatedText } from '../recoil/recoil'

const PolicyContent = () => {

    const textData = useRecoilValue(translatedText);

    return (
        <ul className={styles.content_box}>
            <ul>
                <p></p>
                <p>{textData['80']()}</p>
            </ul>
            <ul>
                <p>{textData['81']()}</p>
                <li>{textData['82']()}</li>
                <li>{textData['83']()}</li>
                <li>{textData['84']()}</li>
                <li>{textData['85']()}</li>
                <li>{textData['86']()}</li>
            </ul>
            <ul>
                <p>{textData['87']()}</p>
                <li>{textData['88']()}</li>
                <li>{textData['89']()}</li>
                <li>{textData['90']()}</li>
            </ul>
            <ul>
                <p>{textData['91']()}</p>
                <li className={styles.description}>{textData['92']()}</li>
                <li>{textData['93']()}</li>
                <li>{textData['94']()}</li>
                <li>{textData['95']()}</li>
            </ul>
            <ul>
                <p>{textData['96']()}</p>
                <p>{textData['97']()}</p>
                <li>{textData['98']()}</li>
                <li>{textData['99']()}</li>
                <li>{textData['100']()}</li>
                <li>{textData['101']()}</li>
            </ul>
            <ul>
                <p>{textData['102']()}</p>
                <li>{textData['103']()}</li>
                <li>{textData['104']()}</li>
                <li>{textData['105']()}</li>
                <li>{textData['106']()}</li>
                <li>{textData['107']()}</li>
                <li>{textData['108']()}</li>
                <li className={styles.description}>{textData['109']()}</li>
            </ul>
            <ul>
                <p>{textData['110']()}</p>
                <li>{textData['111']()}</li>
            </ul>
            <ul>
                <p>{textData['112']()}</p>
                <li>{textData['113']()}</li>
            </ul>
        </ul >
    )
}

export default PolicyContent