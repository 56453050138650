import React from 'react';
import styles from './customer.module.css';
import { ASSETS } from '../../asset';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const CustomerButton = ({ link }) => {
    return (
        <a href={link} target='_blank'>
            <button className={styles.cs_button}>
                <img src={ASSETS.head_set} />
            </button>
        </a>
    )
}

export default CustomerButton