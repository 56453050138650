import React, { useState, useEffect } from "react";
import MainText from "../MainText";
import { ASSETS } from "../../asset";
import MemberCard from "../MemberCard";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'


function TeamMember(props) {

    const memberData = [
        {
            id: 1,
            name: "이사님",
            nick_name: "ralph",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.ceo
        },
        {
            id: 2,
            name: "실장님",
            nick_name: "evan",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.team_leader
        },
        {
            id: 3,
            name: "팀장님",
            nick_name: "evan",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.team_leader
        },
        {
            id: 4,
            name: "남재현",
            nick_name: "ely",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.ely
        },
        {
            id: 5,
            name: "이윤지",
            nick_name: "sophia",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.sophia
        },
        {
            id: 6,
            name: "김지후",
            nick_name: "hoo",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.hoo
        },
        {
            id: 7,
            name: "백종훈",
            nick_name: "markus",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.markus
        },
        {
            id: 8,
            name: "심동민",
            nick_name: "kuma",
            task: "Test",
            link: [{ name: 'linkedin', href: "www.linkedin.com" }],
            img: ASSETS.kuma
        },
    ]


    return (
        <React.Fragment>
            <section className={"team-two-section ptb-100 " + (props.isWhite && props.isWhite === true ? '' : 'gray-light-bg')}>
                <div className="container">
                    {!(props.hideTitle && props.hideTitle === true) && (
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-8">
                                <div className="section-heading text-center">
                                    <h2>Our Team Members</h2>
                                    <p>Authoritatively mesh intuitive paradigms vis-a-vis goal-oriented partnerships. Continually extend open-source outside the box thinking after focused catalysts.</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={4}
                        >
                            {
                                memberData?.map((item) => (
                                    <SwiperSlide>
                                        <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                                            <img src={item.img} alt="team" width="120" height="120" className="img-profile  m-auto pb-4" />
                                            <div className="team-content">
                                                <h5 className="mb-0">{item.name}</h5>
                                                <span>{item.nick_name}</span>
                                                <p className="mt-3">Authoritatively engage leading-edge processes tactical capital </p>
                                                <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                                    <li className="list-inline-item">
                                                        <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a className="linkedin" href="/#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div >
                </div >
            </section >
        </React.Fragment >
    );
}

export default TeamMember;