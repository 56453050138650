import React, { useState } from 'react'
import { modal } from 'framer-motion';
import styles from './successModal.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'react-lottie-player';
import { ASSETS } from '../../asset';
import { useRecoilValue } from 'recoil';
import { translatedText } from '../../recoil/recoil';
import TwitterFollowModal from '../TwitterModal/TwitterFollowModal';
import { Button } from 'react-bootstrap';

const SuccessModal = ({
    isOpen,
    onClose,
    drawn,
    getTokenAllUser
}) => {

    const [animationEnded, setAnimationEnded] = useState(false);
    const [followModalState, setFollowModalState] = useState(false);
    const textData = useRecoilValue(translatedText);

    const closeFuc = async () => {
        getTokenAllUser();
        setAnimationEnded(false);
        onClose();
    }

    return (
        <AnimatePresence>
            {
                isOpen && (
                    <motion.div className={styles.container}>
                        {/* <TwitterFollowModal
                            isOpen={followModalState}
                            onClose={() => setFollowModalState(false)}
                            handleSubmit={() => checkInvalidUser()}
                        /> */}
                        <Lottie
                            animationData={require('../../assets/box_open.json')}
                            play
                            loop={false}
                            onComplete={() => {
                                setAnimationEnded(true);
                            }}
                            style={{ opacity: !animationEnded ? 1 : 0 }}
                            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                        />
                        <motion.div
                            initial={{ opacity: 0 }}
                            className={styles.event_content_container}
                            animate={{ opacity: !animationEnded ? 0 : 1 }}
                        >
                            <div>
                                <Lottie
                                    animationData={require('../../assets/event_success.json')}
                                    play={animationEnded}
                                    loop={false}
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </div>
                            <div>
                                <div className={styles.header}>
                                    <button onClick={closeFuc}>
                                        <img src={ASSETS.close_bold} />
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <p>{textData['114']()}</p>
                                        <h1>{drawn?.amount_drawn} EML</h1>
                                    </div>
                                    <div className={styles.modal_textbox}>
                                        <Button onClick={() => window.open(`https://trustbridgex.io/`)}>
                                            Go To TrustBridgeX
                                        </Button>
                                    </div>
                                    {/* <div className={styles.modal_textbox}>
                                        <Button
                                            variant={"primary"}
                                            onClick={() => window.open(`https://www.tropee.com/t/4pzge2UP`)}
                                        >
                                            <p>
                                                {textData['154']()}
                                            </p>
                                        </Button>
                                        <p id={styles.modal_text} style={{ marginTop: 15 }}>{textData['153']()}</p>
                                    </div>
                                    */}
                                    <div className={styles.bonus_event_subscriptions_box}>
                                        <ul className={styles.bonus_event_subscriptions}>
                                            {/* <li>{textData['157']()}</li> */}
                                            <li>{textData['158']()}</li>
                                            <li>{textData['159']()}</li>
                                        </ul>
                                    </div>
                                    <Button onClick={closeFuc} variant={'success'}>{textData['115']()}</Button>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                )
            }
        </AnimatePresence>
    )
}

export default SuccessModal