import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import styles from './roulette.module.css';
import { Wheel } from 'react-custom-roulette';
import { RouletteWheel } from '../../module/react_casino_roulette_src/index';
import { ASSETS } from '../../asset';
import Snow from '../Snow/Snow';
import SuccessModal from '../SuccessModal/SuccessModal';
import RouletteBottom from '../../assets/RouletteBottom';

// import 'react-casino-roulette/dist/index.css';
// import 'casino-roulette/dist/example/styles.css';

const Roulette = ({
    isOpen = true,
    setIsOpen,
    drawn = 82,
    getTokenAllUser
}) => {

    const [spin, setSpin] = useState(false);
    const [spinData, setSpinData] = useState([]);
    const [successModalVisible, setSuccessModalVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setSpin(true);
        }, 2000)

    }, [])

    useEffect(() => {
        console.log(drawn?.rouletteSlotList, '룰렛 리절트')
        setSpinData(drawn?.rouletteSlotList);
    }, [drawn])

    return (
        <>
            <AnimatePresence>
                {
                    isOpen &&
                    <motion.div className={styles.container} onClick={() => setSpin(true)}>
                        <SuccessModal
                            isOpen={successModalVisible}
                            getTokenAllUser={getTokenAllUser}
                            onClose={() => {
                                setIsOpen(false);
                                setSuccessModalVisible(false);
                            }}
                            drawn={drawn}
                        />
                        <div>
                            {
                                spinData?.length > 0 &&
                                <>
                                    <Snow />
                                    <div className={styles.main_text}>
                                        <h1>Lucky EML!</h1>
                                    </div>
                                    <div className={styles.roulette_container}>
                                        <RouletteWheel
                                            start={spin}
                                            // winningBet={String(drawn.amount_drawn)}
                                            winningBet={String(drawn.amount_drawn)}
                                            onSpinningEnd={() => {
                                                setTimeout(() => {
                                                    setSuccessModalVisible(true);
                                                }, 1000)
                                            }}
                                            wheelNumbers={drawn?.rouletteSlotList}
                                        />
                                        <div className={styles.bottom_box}>
                                            <RouletteBottom />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence >
        </>
    )
}

export default Roulette