import { atom, selector } from "recoil";
import { cn, en, indonesia, jp, ko } from "../language/language";

export const language = atom({
    key: 'language',
    default: 'en'
})

export const translatedText = selector({
    key: "translatedText",
    get: ({ get }) => {
        const languageData = get(language);
        const obj = {};

        if (languageData === "en") return en;
        else if (languageData === "ko") return ko;
        else if (languageData === "cn") return cn;
        else if (languageData === 'jp') return jp;
        else if (languageData === 'indonesia') return indonesia
        else return ko;
    },
});

export const appName = selector({
    key: "appName",
    get: ({ get }) => {
        const languageData = get(language);

        if (languageData === "en") return ['TrustBridgeX', 'TrustBridgeX'];
        else if (languageData === "ko") return ['TrustBridgeX', 'TrustBridgeX'];
        else if (languageData === "cn") return ['TrustBridgeX', 'TrustBridgeX'];
        else if (languageData === 'jp') return ['TrustBridgeX', 'TrustBridgeX'];
        else if (languageData === 'indonesia') return ['TrustBridgeX', 'TrustBridgeX']
        else return ['TrustBridgeX', 'TrustBridgeX'];
    }
})