import { APP } from "../asset"

export const ko = {
    "1": () => { return "가장 손쉬운 암호화폐 거래" },
    "2": (app_name) => {
        return `${app_name}은 기존의 거래소를 통한 암호화폐 현금화, 이종토큰화
    방식을 유저간 거래를 통해 간편하게, 스마트 컨트랙트를 통해서
    안전하게 거래하도록 전환시킨 암호화폐 개인거래 플랫폼입니다.`
    },
    "3": () => "다운로드",
    "4": () => "소개 영상",
    "5": (app_name) => `${app_name} 은 무엇인가요?`,
    "6": (app_name) => {
        return `${app_name} 은 기존의 거래소를 통한 암호화폐 현금화, 이종토큰화 방식을 유저간 거래를 통해 간편하게, 스마트 컨트랙트를 통해서 안전하게 거래하도록 전환시킨 암호화폐 개인거래 플랫폼입니다.`
    },
    "7": (app_name) => `${app_name} 을 사용해야 하는 이유`,
    "8": (app_name) => `${app_name}  에스크로는 유저가 판매를 등록하면 해당 토큰을 전용 에스크로에 보관하여
    거래 과정에서 구매자가 현금을 입금하였으나
    토큰을 받지 못하는 경우를 최소화하고,
    판매자 또한 따로 토큰을 전송할 필요 없이 거래가 성사됨에 따라
    전용 에스크로가 구매자의 지갑으로 자동으로 토큰을 전송하여
    거래 과정에서 토큰을 주고받는 과정을 자동화하였습니다.`,
    "9": () => '전송',
    "10": () => '원하는곳 어디로는 바로 전송 가능',
    "11": () => '스왑',
    "12": () => '더 빠른 전송을 위해 토큰 포인트로 스왑',
    "13": () => '거래',
    "14": () => '전 세계 모든 사람과 언제든 거래 가능',
    "15": () => '속도',
    "16": () => '어떤 블록체인 시스템보다 빠른 속도',
    "17": () => '인증',
    "18": () => '복잡한 중앙화 거래소의 인증 시스템을 없앤 간편',
    "19": () => '출금',
    "20": () => '언제든지 내가 원할때 바로 현금 교환 가능',
    "21": () => '앱 스크린샷',
    "22": () => '유저들의 편의성을 고려한 깔끔한 UI',
    "23": () => `의 주요 기능`,
    "24": (app_name) => `${app_name}의 주요적인 기능입니다`,
    "25": () => '나의 지갑',
    "26": () => `회원가입 과정에서 자동으로 생성된 암호화폐 지갑을 통해
    자산/거래내역 확인, 자산 내보내기, 나의 지갑 주소 내보내기 등의
    기능을 지원합니다.`,
    "27": () => '토큰 판매 / 등록',
    "28": () => `USDT, BNB, WETH 등 유저들이 보유한 토큰을 등록하여
    달러, 원화 등 화폐 종류에 구애받지 않고 판매가 가능합니다.`,
    "29": () => '토큰 구매 신청 / 판매 목록',
    "30": () => `판매등록 된 토큰을 실시간 유저간 개인거래 방식을 통해
    구매가 가능합니다.`,
    "31": () => `유저 수`,
    "32": () => '앱 다운로드 수',
    "33": () => '거래 횟수',
    "34": () => '거래 수수료',
    "35": () => '지원하는 토큰',
    "36": (app_name) => `${app_name}가 거래에 지원하는 토큰입니다.`,
    "37": () => '사전 등록 이벤트',
    "38": () => '이메일 주소를 적어주세요!',
    "39": () => '지금 바로 신청해보세요!',
    "40": () => '신청하기',
    "41": () => `사전 등록 이벤트`,
    "42": () => `이벤트 참여 유의사항`,
    "43": () => `이메일 / 지갑주소 / 휴대폰 번호 중 하나라도 이미 신청하신 내역이 있다면 이전에 신청하신 내역까지 강제로 취소됩니다.`,
    "44": () => `출시 이후 입력 하신 이메일로 회원가입 하셔야 보상을 받을 수 있습니다.`,
    "45": () => `연락처를 입력 하실 때 국가 코드를 꼭 입력하셔야 정상적인 처리가 가능합니다.`,
    "46": () => `2000명 추첨을 진행 한 후 당첨자에 한해 지급이 진행됩니다.`,
    "47": () => `입력하신 번호로 발송드린 인증번호를 입력하세요`,
    "48": () => `인증완료`,
    "49": () => `인증받기`,
    "50": () => `신청하기`,
    "51": () => `신청 실패`,
    "52": () => `존재하지 않는 지갑주소 입니다`,
    "53": () => `신청 실패`,
    "54": () => `모든 정보를 입력 해주세요`,
    "55": () => `신청 실패`,
    "56": () => `휴대폰 인증을 진행 해주세요`,
    "57": () => `신청 실패`,
    "58": () => `이메일을 입력 해주세요`,
    "59": () => `신청 실패`,
    "60": () => `지갑주소를 입력 해주세요`,
    "61": () => `신청 실패`,
    "62": () => `이미 신청하신 정보가 있습니다`,
    "63": () => `인증 실패`,
    "64": () => `인증번호가 일치하지 않습니다`,
    "65": () => `인증 실패`,
    "66": () => `이메일 형식이 일치하지 않습니다`,
    "67": () => `이벤트 신청이 완료되었습니다`,
    "68": () => `홈으로`,
    "69": () => `이메일을 입력해주세요`,
    "70": () => `0x로 시작하는 지갑주소를 입력해주세요`,
    "71": () => `인증 받으실 전화번호를 입력해주세요`,
    "72": () => `혹시 인증번호가 발송되지 않았나요?`,
    "73": () => `다시시도`,
    // start
    // marquee
    "74": (address, amount) => `${address} ${amount} 당첨`,
    // event modal
    // "75": () => `이미 종료된 이벤트 입니다`,
    "75": () => `금일 이벤트 수량이 모두 소진되었습니다`,
    "76": () => `개인정보 수집 및 이용동의에 체크해주세요`,
    // event
    "77": () => `이벤트 참여를 위한`,
    "78": () => `개인정보 수집 및 이용 동의`,

    // input
    "79": (label) => `${label} 계정을 적어주세요 (선택사항)`,

    // privacy
    "80": () => `- TrustBridgeX (이하 "회사" 또는 "우리")는 개인정보 보호를 중요하게 생각하며 본 개인정보 처리방침은 우리 앱 및 서비스(이하 "서비스" 또는 "앱")를 통해 수집되는 개인정보의 수집, 사용, 보관 및 공개에 관한 정보를 제공합니다. 본 방침은 앱 사용자(이하 "사용자" 또는 "당신")에게 적용됩니다.`,
    "81": () => `개인정보는 다음 목적을 위해 처리될 수 있습니다`,
    "82": () => `- 서비스 제공 및 운영`,
    "83": () => `- 사용자 지원 및 응답`,
    "84": () => `- 앱 개선 및 사용자 경험 향상`,
    "85": () => `- 법적 의무 준수`,
    "86": () => `- 수집하는 개인정보 항목`,
    "87": () => `우리는 다음과 같은 개인정보를 수집할 수 있습니다`,
    "88": () => `- 식별 정보: 이름, 이메일 주소`,
    "89": () => `- 기기 정보: 기기 식별자, IP 주소, 브라우저 유형 및 버전`,
    "90": () => `- 위치 정보: 지리적 위치 정보 (위치 서비스 활성화 시)`,
    "91": () => `개인정보 수집 방법`,
    "92": () => `우리는 다음과 같은 방법으로 개인정보를 수집할 수 있습니다`,
    "93": () => `- 앱 사용자로부터 직접 제공한 정보`,
    "94": () => `- 자동으로 수집된 정보: 쿠키 및 유사한 추적 기술을 통해 수집`,
    "95": () => `우리는 개인정보를 사용자가 서비스 사용을 중단할 때까지 또는 법률적 의무를 이행하기 위해 필요한 기간 동안 보관합니다.`,
    "96": () => `개인정보의 공유 및 제공`,
    "97": () => `우리는 사용자의 개인정보를 다음과 같은 경우에 제3자와 공유할 수 있습니다`,
    "98": () => `- 법률적 요구에 따라`,
    "99": () => `- 사용자의 동의가 있는 경우`,
    "100": () => `- 우리와 개인정보 처리를 위한 계약을 체결한 경우`,
    "101": () => `개인정보의 권리`,
    "102": () => `우리는 사용자에게 다음과 같은 개인정보 관련 권리를 부여합니다`,
    "103": () => `- 개인정보 열람 및 수정`,
    "104": () => `- 개인정보 삭제`,
    "105": () => `- 개인정보 처리에 대한 동의 철회`,
    "106": () => `- 개인정보 처리 제한`,
    "107": () => `- 개인정보 이전 제한`,
    "108": () => `- 보안`,
    "109": () => `우리는 사용자의 개인정보를 보호하기 위해 합리적인 보안 조치를 취하고 있습니다. 그러나 인터넷 통신은 100% 안전하지 않을 수 있으며, 사용자가 개인정보를 전송할 때 우리는 그 안전을 보장할 수 없습니다.`,
    "110": () => `개인정보 처리 방침의 변경`,
    "111": () => `우리는 본 개인정보 처리방침을 변경할 권리를 보유하며 변경 사항은 본 페이지에 게시됩니다. 변경 사항이 발생할 경우 사용자에게 통지합니다.`,
    "112": () => `연락처`,
    "113": () => `개인정보 처리방침에 대한 질문, 제안 또는 민원이 있을 경우 trustbridgex@gmail.com 으로 문의해 주시기 바랍니다.
    본 개인정보 처리방침은 2023년 11월 07일에 효력을 발생합니다`,
    // Event SuccessModal
    "114": () => `축하합니다!`,
    "115": () => `완료`,
    "116": () => 'TrustBridgeX 개인정보 처리방침',
    // 2023.11.16 추가
    "117": () => '휴대폰 번호 형식이 일치하지 않습니다.',
    "118": () => '트위터 로그인을 먼저 진행 해주세요!',
    "119": () => '서버와의 요청중 에러가 발생했습니다. 다시 시도해주세요.',
    "120": () => '트위터 로그인을 다시 시도 해주세요',
    "121": () => '인증번호를 전송 하였습니다',
    "122": () => '트위터 로그인에 성공하였습니다',
    "123": () => '지원되지 않는 접속 환경이거나, 메타마스크가 설치되어 있지 않습니다.',
    "124": () => 'EML 토큰이 추가되었습니다',
    "125": () => '메타마스크에 EML토큰 추가하기',
    "126": () => '추가로 이벤트를 진행 하고 싶으신가요?',
    // 2023.11.17
    "127": () => `이벤트 기간`,
    "128": () => `1월 22일부터 1월 26일까지(조기 종료될 수 있습니다)`,
    "129": () => `참가 대상`,
    "130": () => `사전예약에 참여하는 모든 유저`,
    "131": () => `참여 방법`,
    "132": () => `이메일, 텔레그램, 전화번호 인증 후 사전예약 등록`,
    "133": () => `보너스 이벤트: 이벤트 양식 작성`,
    "134": () => `이벤트 혜택`,
    "135": () => `EML 토큰이 랜덤으로 당첨됩니다.`,
    "136": () => `혜택 증정일`,
    "137": () => `2024년 1월 중으로 앱 내 유저 본인의 지갑으로 지급됩니다.`,
    "138": () => `규정 가이드 라인`,
    "139": () => `전화번호, E-mail을 모두 기입해야합니다.`,
    "140": () => `이메일, 전화번호, 텔레그램 인증이 확인되어야 진행이 가능합니다.`,
    "141": () => `중복 가입은 불가능 합니다.`,
    "142": () => `동일한 명의자의 중복 가입이 확인될 경우 당첨이 취소될 수 있습니다.`,
    "143": () => `본 이벤트에는 한 번만 참여할 수 있습니다.`,
    "144": () => `필요한 인증 과정을 모두 거쳤을 경우에만 사전예약 버튼이 활성화 됩니다. (인증 불가시 비활성화)`,
    "145": () => `활성된 버튼을 누르면 사전예약 참여가 완료됩니다.`,
    "146": () => `혜택은 출시일(날짜)에 참여한 유저 앱 내 지갑으로 지급됩니다.`,
    "148": () => `추후 지급받은 EML을 TrustBridgeX에서 출금할 때는 수수료로 EML이 사용됩니다. 수수료를 위한 별도의 ETH를 입금할 필요없습니다.
    (출금 시 지급받은 EML 중 일부가 수수료로 사용됩니다.)`,
    "149": () => `잘못된 정보 입력으로 발송 오류 시 귀사는 어떠한 책임도 지지 않습니다.`,
    "150": () => `본 이벤트는 TrustBridgeX의 사정으로 예고없이 변경 또는 조기 종료될 수 있습니다.`,
    "151": () => `TrustBridgeX는 서비스 사전예약, 각종 소식 및 이벤트 알림 발송, 회원 식별을 위해 정보를 수집하며, 이벤트에 참여시 정보 제공에 동의한 것으로 간주합니다.`,
    // Success Modal
    "153": () => `위의 링크로 이동하여 참여 양식을 작성하면\n보너스 이벤트에 참여 하실 수 있습니다.`,
    "154": () => `보너스 이벤트 참여하기`,
    // 20231201 추가
    "156": () => `각각의 양식을 작성 완료시 미션 포인트를 획득 합니다.`,
    "157": () => `보너스 이벤트 참여후 증정금은 TrustBridgeX 런칭후 등록하신 메일 주소로 가입하시면 해당 플랫폼 내에서 지급됩니다.`,
    "158": () => `해당 추가 EML은 TrusutBridgeX가 정식 론칭되면 플랫폼 내에서 지급됩니다.`,
    "159": () => `정확한 론칭 시기와 에어드랍 일정 및 보상 수량은 추후 공지를 살펴주시길 바랍니다.`,
    // 20231207 추가
    "161": () => `네트워크를 이더리움 메인넷으로 변경해주세요`,
    // 20231214 추가
    "162": () => `참여 가능 인원`,
    // 20231226 추가
    "163": () => `혜택 안내`,
    "164": () => `20 ~ 6000 상당의 EML`,
    "165": () => `추가 이벤트 참여시`,
    "166": () => `메일 인증: 15 EML`,
    "167": () => `텔레그램 인증: 15 EML`,
    "168": () => `트위터 인증: 15 EML`,
    "169": () => `1월 중 앱 출시 안내 이메일이 발송됩니다  이메일을 꼭 확인해주세요`,
    // 20240111 추가
    "170": () => `텔레그램 로그인을 진행해주세요.`,
    // 20240117 추가
    "171": () => `이메일이 발송되었습니다.`,
    "172": () => `인증번호가 일치하지 않습니다.`,
    "173": () => `인증이 완료되었습니다.`,
    "174": () => `인증하기`,
    "175": () => `인증완료`,
    "176": () => `입력하신 이메일로 발송드린 인증번호를 입력하세요`,
    "177": () => `연결해제`,
    "178": () => `텔레그램 연동에 성공하였습니다.`,
    "179": () => '오늘 200명의 참가자에 대한 에어드랍 할당이 완료되었습니다. 매일 오전 6:00 UTC에 추가 참가 기회가 생깁니다. 관심 가져 주셔서 감사합니다. 다음 라운드에 참여하시기 바랍니다!',
    // 20240118 추가
    "180": () => `1차 이벤트 참여유저는 제외`
}

export const en = {
    "1": () => { return "The easiest cryptocurrency trading" },
    "2": (app_name) => {
        return `${app_name} is a cryptocurrency peer-to-peer trading platform that simplifies and secures cryptocurrency transactions through user-to-user trading and smart contracts, bypassing traditional exchanges.`;
    },
    "3": () => "Download",
    "4": () => "Introduction Video",
    "5": (app_name) => `${app_name} is what?`,
    "6": (app_name) => {
        return `${app_name} is a cryptocurrency peer-to-peer trading platform that simplifies and secures cryptocurrency transactions through user-to-user trading and smart contracts, bypassing traditional exchanges.`;
    },
    "7": (app_name) => `Why should you use ${app_name}?`,
    "8": (app_name) => `${app_name} Escrow stores the tokens in escrow when a user registers a sale, minimizing the risk of buyers depositing cash but not receiving tokens. It also automates the token transfer process, ensuring that tokens are automatically transferred from the escrow to the buyer's wallet upon trade confirmation, eliminating the need for separate token transfers.`,
    "9": () => 'Send',
    "10": () => 'Send to your desired location instantly',
    "11": () => 'Swap',
    "12": () => 'Swap to token points for faster transactions',
    "13": () => 'Trade',
    "14": () => 'Trade with anyone, anytime, worldwide',
    "15": () => 'Speed',
    "16": () => 'Faster than any blockchain system',
    "17": () => 'Authentication',
    "18": () => 'Simplified authentication, eliminating complex centralized exchange systems',
    "19": () => 'Withdraw',
    "20": () => 'Exchange for cash whenever you want',
    "21": () => 'App Screenshots',
    "22": () => 'Clean UI designed for user convenience',
    "23": () => 'Key Features',
    "24": (app_name) => `Key features of ${app_name}`,
    "25": () => 'My Wallet',
    "26": () => `Automatically created cryptocurrency wallet during registration allows for asset/transaction overview, asset export, my wallet address export, and more.`,
    "27": () => 'Token Sale/Listing',
    "28": () => `Register tokens like USDT, BNB, WETH for sale, enabling selling without constraints of currency type.`,
    "29": () => 'Token Purchase Request/Sales Listings',
    "30": () => `Buy listed tokens through real-time user-to-user trading.`,
    "31": () => `User Count`,
    "32": () => 'App Download Count',
    "33": () => 'Number of Trades',
    "34": () => 'Trade Fees',
    "35": () => 'Supported Tokens',
    "36": (app_name) => `Tokens supported by ${app_name} for trading.`,
    "37": () => 'Pre-Registration Event',
    "38": () => 'Please enter your email address!',
    "39": () => 'Apply now!',
    "40": () => 'Apply',
    "41": () => "Pre-Registration Event",
    "42": () => "Event Participation Guidelines",
    "43": () => "If you have previously applied with your email, wallet address, or phone number, your previous application will be forcibly canceled.",
    "44": () => "You must sign up with the email you provided after the release to receive rewards.",
    "45": () => "When entering your contact information, please make sure to include the country code for proper processing.",
    "46": () => "After conducting a random draw of 2000 people, the winners will receive their rewards.",
    "47": () => "Please enter the verification code sent to your provided number.",
    "48": () => "Verification",
    "49": () => "Get Verified",
    "50": () => "Apply Now",
    "51": () => "Application Failed",
    "52": () => "Invalid Wallet Address",
    "53": () => "Application Failed",
    "54": () => "Please fill in all the information",
    "55": () => "Application Failed",
    "56": () => "Please proceed with mobile phone verification",
    "57": () => "Application Failed",
    "58": () => "Please enter your email",
    "59": () => "Application Failed",
    "60": () => "Please enter your wallet address",
    "61": () => "Application Failed",
    "62": () => "You have already applied",
    "63": () => "Verification Failed",
    "64": () => "Verification code does not match",
    "65": () => "Verification Failed",
    "66": () => "Email format is not valid",
    "67": () => "Event application has been completed",
    "68": () => "Home",
    "69": () => "Please enter your email",
    "70": () => "Please enter a wallet address starting with 0x",
    "71": () => "Please enter the phone number for verification",
    "72": () => `Have you not received the verification code by any chance?`,
    "73": () => `Try again`,
    "74": (address, amount) => `${address} ${amount} Winner`,
    // event modal
    "75": () => `The quantity of today's events has been fully depleted`,
    "76": () => `Please check the consent for personal information collection and usage`,
    // event
    "77": () => `For event participation,`,
    "78": () => `Consent for personal information collection and usage`,

    // input
    "79": (label) => `Please write your ${label} account (optional)`,

    // privacy
    "80": () => `- TrustBridgeX (hereinafter "the Company" or "we") places a high value on the protection of personal information and provides information on the collection, use, storage, and disclosure of personal information collected through our app and services (hereinafter "Services" or "App"). This policy applies to app users (hereinafter "Users" or "you").`,
    "81": () => `Personal information may be processed for the following purposes:`,
    "82": () => `- Provision and operation of services`,
    "83": () => `- User support and response`,
    "84": () => `- App improvement and enhancement of user experience`,
    "85": () => `- Compliance with legal obligations`,
    "86": () => `- Personal information collected`,
    "87": () => `We may collect the following personal information:`,
    "88": () => `- Identification information: name, email address`,
    "89": () => `- Device information: device identifier, IP address, browser type, and version`,
    "90": () => `- Location information: geographical location information (when location services are enabled)`,
    "91": () => `Methods of collecting personal information`,
    "92": () => `We may collect personal information through the following methods:`,
    "93": () => `- Information provided directly by app users`,
    "94": () => `- Information collected automatically: collected through cookies and similar tracking technologies`,
    "95": () => `We retain personal information for the period necessary for the user to discontinue the use of the service or to fulfill legal obligations.`,
    "96": () => `Sharing and provision of personal information`,
    "97": () => `We may share the user's personal information in the following cases:`,
    "98": () => `- According to legal requirements`,
    "99": () => `- With the user's consent`,
    "100": () => `- When a contract has been concluded for personal information processing with us`,
    "101": () => `Rights regarding personal information`,
    "102": () => `We grant users the following rights regarding personal information:`,
    "103": () => `- Access to and modification of personal information`,
    "104": () => `- Deletion of personal information`,
    "105": () => `- Withdrawal of consent for personal information processing`,
    "106": () => `- Restriction of personal information processing`,
    "107": () => `- Restriction of personal information transfer`,
    "108": () => `- Security`,
    "109": () => `We take reasonable security measures to protect the user's personal information. However, internet communication may not be 100% secure, and we cannot guarantee the security when users transmit personal information.`,
    "110": () => `Changes to the Privacy Policy`,
    "111": () => `We reserve the right to change this privacy policy, and any changes will be posted on this page. Users will be notified of any changes.`,
    "112": () => `Contact`,
    "113": () => `If you have any questions, suggestions, or complaints regarding the privacy policy, please contact us at trustbridgex@gmail.com. This privacy policy is effective as of November 07, 2023.`,
    // Event SuccessModal
    "114": () => `Congratulations!`,
    "115": () => `Completed`,
    "116": () => 'TrustBridgeX Privacy Policy',
    // 2023.11.16 추가
    "117": () => 'The phone number format does not match.',
    "118": () => 'Please proceed with Twitter login first.',
    "119": () => 'An error occurred during the request to the server. Please try again.',
    "120": () => 'Please retry Twitter login.',
    "121": () => 'The verification code has been sent.',
    "122": () => 'Twitter login successful.',
    "123": () => 'Unsupported browser or MetaMask installation not detected. Please check.',
    "124": () => 'EML token has been added.',
    "125": () => 'Add EML token to MetaMask',
    "126": () => 'Would you like to proceed with additional events?',
    "127": () => `Event Dates`,
    "128": () => `From January 22nd to January 26th (subject to early termination)`,
    "129": () => `Participating Audience`,
    "130": () => `All users who are participating on Pre-Registration`,
    "131": () => `Participation Method`,
    "132": () => `Pre-register after email, Telegram, and phone number verification`,
    "133": () => `Bonus Event: Fill in event format`,
    "134": () => `Event Rewards`,
    "135": () => `Random lucky draw for $EML between 5 to 3,000`,
    "136": () => `Dates for redeeming rewards`,
    "137": () => `To be credited to the user's in-app wallet by January 2024.`,
    "138": () => `Regulation Guidelines`,
    "139": () => `Both phone number and email must be provided.`,
    "140": () => `Email, phone number, and Telegram verification must be confirmed to proceed.`,
    "141": () => `Duplicate registrations are not allowed.`,
    "142": () => `If duplicate registration of the same person is confirmed, the winning may be canceled.`,
    "143": () => `Participation is limited to once in this event.`,
    "144": () => `The pre-reservation button will be activated only if all the necessary verification processes are completed. (Deactivated if verification fails)`,
    "145": () => `Clicking the activated button completes the pre-reservation participation.`,
    "146": () => `Benefits will be credited to the user's in-app wallet on the release date.`,
    "148": () => `When withdrawing EML received later from TrustBridgeX, EML will be used as a fee. There is no need to deposit separate ETH for the fee.
      (A portion of the received EML will be used as a fee when withdrawing.)`,
    "149": () => `In case of sending errors due to incorrect information input, the company assumes no responsibility.`,
    "150": () => `This event may be changed or terminated without notice due to TrustBridgeX's circumstances.`,
    "151": () => `TrustBridgeX collects information for service pre-reservation, various news and event notifications, and member identification. Participation in the event is considered as agreement to provide information.`,

    "152": () => `All collected information will be discarded 3 months after the end of the event period.`,
    // Success Modal
    "153": () => `Participants can join the bonus event\nby moving to the link above and\nfilling out the participation form.`,
    "154": () => `Participate in bonus events`,
    // 20231201 추가
    "155": () => `Only those who participate in the event will receive additional $EML`,
    "156": () => `On completion of each form, participants will acquire mission points.`,
    "157": () => `After participating in the bonus event, the bonus amount will be credited within the platform upon registration with the provided email address after TrustBridgeX launch.`,
    "158": () => `The additional EML will be dispensed within the TrustBridgeX platform upon its official launch.`,
    "159": () => `Please stay tuned for future announcements regarding the exact launch date, airdrop schedule, and reward amounts.`,
    "160": () => `If you do not wish to participate in additional events, you may opt out. The $EML won through official event participation will be officially distributed.`,
    "161": () => `Please change the network to the Ethereum mainnet.`,
    "162": () => `Available Participants`,
    // 20231226 추가
    "163": () => `Benefits Information`,
    "164": () => `EML equivalent to 20 ~ 6000`,
    "165": () => `Participate in Additional Events`,
    "166": () => `Email Verification: 15 EML`,
    "167": () => `Telegram Verification: 15 EML`,
    "168": () => `Twitter Verification: 15 EML`,
    "169": () => `In January, an email will be sent to inform about the app release. Please make sure to check your email.`,
    "170": () => `Please proceed with Telegram login.`,
    "171": () => `Email has been sent.`,
    "172": () => `Authentication code does not match.`,
    "173": () => `Authentication completed.`,
    "174": () => `Authenticate`,
    "175": () => `Completed`,
    "176": () => `Please enter the verification code we sent to your email.`,
    "177": () => `Disconnect`,
    "178": () => `Successfully linked to Telegram.`,
    "179": () => `The airdrop allocation for 200 participants has been fully distributed today. Additional spots will become available daily at 6:00 AM UTC. Thank you for your interest, and be sure to participate in the next round!`,
    "180": () => `Excluding participants in the first round of the event`
};

export const cn = {
    "1": () => { return "最简单的加密货币交易" },
    "2": (app_name) => {
        return `${app_name} 是一个加密货币点对点交易平台，通过用户间交易和智能合约，简化并确保加密货币交易，避开传统交易所。`;
    },
    "3": () => "下载",
    "4": () => "介绍视频",
    "5": (app_name) => `${app_name} 是什么？`,
    "6": (app_name) => {
        return `${app_name} 是一个加密货币点对点交易平台，通过用户间交易和智能合约，简化并确保加密货币交易，避开传统交易所。`;
    },
    "7": (app_name) => `为什么要使用${app_name}？`,
    "8": (app_name) => `${app_name} 的托管存储用户注册销售时的代币，降低了买家存入现金但未收到代币的风险。它还自动化了代币转移过程，确保在交易确认时代币自动从托管转移到买家的钱包，消除了分开的代币转移的需求。`,
    "9": () => '发送',
    "10": () => '立即发送到您想要的地方',
    "11": () => '交换',
    "12": () => '用代币积分进行更快的交易',
    "13": () => '交易',
    "14": () => '与任何人，随时随地进行交易',
    "15": () => '速度',
    "16": () => '比任何区块链系统都要快',
    "17": () => '认证',
    "18": () => '简化认证，消除复杂的中心化交易所系统',
    "19": () => '提取',
    "20": () => '随时兑换现金',
    "21": () => '应用截图',
    "22": () => '为用户方便而设计的清晰UI',
    "23": () => '主要功能',
    "24": (app_name) => `${app_name} 的主要功能`,
    "25": () => '我的钱包',
    "26": () => `自动创建的加密货币钱包允许查看资产/交易概览、资产导出、我的钱包地址导出等功能。`,
    "27": () => '代币销售/上市',
    "28": () => `注册像USDT、BNB、WETH等代币进行销售，允许销售不受货币类型的限制。`,
    "29": () => '代币购买请求/销售列表',
    "30": () => `通过实时的用户之间交易购买已列出的代币。`,
    "31": () => '用户数',
    "32": () => '应用下载次数',
    "33": () => '交易次数',
    "34": () => '交易费',
    "35": () => '支持的代币',
    "36": (app_name) => `由${app_name}支持的用于交易的代币。`,
    "37": () => '事前登记活动',
    "38": () => '请提供您的电子邮件地址！',
    "39": () => '立即申请！',
    "40": () => '申请',
    "41": () => "预订活动",
    "42": () => "事前登记活动",
    "43": () => "如果您以前使用电子邮件、钱包地址或电话号码申请过，您之前的申请将被强制取消。",
    "44": () => "您必须使用发布后提供的电子邮件地址注册才能获得奖励。",
    "45": () => "在输入联系信息时，请确保包括国家区号以进行正常处理。",
    "46": () => "在随机抽取了2000名获奖者后，获奖者将收到奖励。",
    "47": () => "请输入发送到您提供的号码的验证码。",
    "48": () => "验证已完成",
    "49": () => "获取验证",
    "50": () => "现在申请",
    "51": () => "申请失败",
    "52": () => "无效的钱包地址",
    "53": () => "申请失败",
    "54": () => "请填写所有信息",
    "55": () => "申请失败",
    "56": () => "请进行手机验证",
    "57": () => "申请失败",
    "58": () => "请输入您的电子邮件",
    "59": () => "申请失败",
    "60": () => "请输入您的钱包地址",
    "61": () => "申请失败",
    "62": () => "您已经申请过了",
    "63": () => "验证失败",
    "64": () => "验证码不匹配",
    "65": () => "验证失败",
    "66": () => "电子邮件格式不正确",
    "67": () => "活动申请已完成",
    "68": () => "主页",
    "69": () => "请输入您的电子邮件",
    "70": () => "请输入以0x开头的钱包地址",
    "71": () => "请输入用于验证的电话号码",
    "72": () => "您有没有收到验证码？",
    "73": () => "请再试一次",
    // marquee
    "74": (address, amount) => `${address} ${amount} 获奖者`,
    // event modal
    "75": () => `今天的活动数量已经全部用完`,
    "76": () => `请勾选个人信息收集和使用同意`,
    // event
    "77": () => `为了参加活动，`,
    "78": () => `个人信息收集和使用同意`,

    // input
    "79": (label) => `请填写您的 ${label} 账户（可选）`,

    // privacy
    "80": () => `- TrustBridgeX（以下简称"公司"或"我们"）非常重视个人信息保护，并提供有关通过我们的应用程序和服务（以下简称"服务"或"应用程序"）收集的个人信息的收集、使用、存储和披露的信息。本政策适用于应用程序用户（以下简称"用户"或"您"）。`,
    "81": () => `个人信息可能出于以下目的而进行处理：`,
    "82": () => `- 提供和运营服务`,
    "83": () => `- 用户支持和响应`,
    "84": () => `- 应用程序改进和提高用户体验`,
    "85": () => `- 遵守法律义务`,
    "86": () => `- 收集的个人信息`,
    "87": () => `我们可能收集以下个人信息：`,
    "88": () => `- 识别信息：姓名、电子邮件地址`,
    "89": () => `- 设备信息：设备标识符、IP地址、浏览器类型和版本`,
    "90": () => `- 位置信息：地理位置信息（启用位置服务时）`,
    "91": () => `个人信息收集方法`,
    "92": () => `我们可能通过以下方法收集个人信息：`,
    "93": () => `- 应用程序用户直接提供的信息`,
    "94": () => `- 自动收集的信息：通过Cookie和类似的跟踪技术收集`,
    "95": () => `我们会在用户终止使用服务或履行法定义务所需的期间内保留个人信息。`,
    "96": () => `共享和提供个人信息`,
    "97": () => `在以下情况下，我们可能与用户共享其个人信息：`,
    "98": () => `- 根据法律要求`,
    "99": () => `- 在用户同意的情况下`,
    "100": () => `- 与我们订立个人信息处理合同的情况`,
    "101": () => `关于个人信息的权利`,
    "102": () => `我们授予用户以下关于个人信息的权利：`,
    "103": () => `- 访问和修改个人信息`,
    "104": () => `- 删除个人信息`,
    "105": () => `- 撤回个人信息处理的同意`,
    "106": () => `- 限制个人信息处理`,
    "107": () => `- 限制个人信息传输`,
    "108": () => `- 安全`,
    "109": () => `我们采取合理的安全措施来保护用户的个人信息。然而，互联网通信可能不是100%安全的，当用户传输个人信息时，我们无法保证安全性。`,
    "110": () => `隐私政策的更改`,
    "111": () => `我们保留更改本隐私政策的权利，并将任何更改发布在本页面上。用户将收到有关任何更改的通知。`,
    "112": () => `联系方式`,
    "113": () => `如果您对隐私政策有任何问题、建议或投诉，请通过 trustbridgex@gmail.com 与我们联系。本隐私政策自2023年11月07日起生效。`,
    // Event SuccessModal
    "114": () => `恭喜！`,
    "115": () => `已完成`,
    "116": () => `TrustBridgeX 隐私政策`,
    // 2023.11.16 추가
    "117": () => '手机号码格式不匹配。',
    "118": () => '请先进行Twitter登录。',
    "119": () => '在与服务器请求过程中发生错误。请重试。',
    "120": () => '请重试Twitter登录。',
    "121": () => '已发送验证码。',
    "122": () => 'Twitter登录成功。',
    "123": () => '不支持的浏览器或未检测到MetaMask安装。请检查。',
    "124": () => 'EML令牌已添加。',
    "125": () => '将EML令牌添加到MetaMask',
    "126": () => '您是否想要进行其他事件？',
    "127": () => `活动期间`,
    "128": () => `从1月22日到1月26日（可能提前结束）`,
    "129": () => `参与对象`,
    "130": () => `所有参与预约的用户`,
    "131": () => `参与方式`,
    "132": () => `在电子邮件、Telegram和电话号码验证后进行预注册`,
    "133": () => `奖励活动：填写活动表格`,
    "134": () => `活动福利`,
    "135": () => `将随机抽取$EML 5至3000个`,
    "136": () => `福利发放日`,
    "137": () => `将于2024年1月前后存入用户的应用内钱包。`,
    "138": () => `规定指南`,
    "139": () => `必须提供电话号码和电子邮件。`,
    "140": () => `必须确认电子邮件、电话号码和Telegram验证后才能进行。`,
    "141": () => `不允许重复注册。`,
    "142": () => `如果确认同一人重复注册，可能取消中奖资格。`,
    "143": () => `本活动只能参与一次。`,
    "144": () => `只有完成所有必要的验证过程，预约按钮才会激活。（验证失败则停用）`,
    "145": () => `点击激活的按钮即可完成预约参与。`,
    "146": () => `福利将于发布日期通过用户应用内钱包发放。`,
    "148": () => `在以后从TrustBridgeX提取EML时，EML将作为手续费使用。无需为手续费单独存入ETH。
    （提取时将使用部分已收到的EML作为手续费。）`,
    "149": () => `由于输入错误导致发送错误，公司概不负责。`,
    "150": () => `本活动可能因TrustBridgeX的情况而在未经通知的情况下更改或提前结束。`,
    "151": () => `TrustBridgeX收集信息用于服务预约、各种新闻和活动通知以及会员识别。参与活动被视为同意提供信息。`,
    "152": () => `收集的信息将在活动结束后的3个月内销毁`,
    // Success Modal
    "153": () => `点击下面的按钮并填写参与表格\n即可参加奖金活动。`,
    "154": () => `参加奖金活动`,
    // 20231201 추가
    "155": () => `仅限参与活动的人发送额外的 $EML。`,
    "156": () => `完成每个表格后，将获得任务积分。`,
    "157": () => `参与奖励活动后，在TrustBridgeX启动后使用提供的电子邮件地址注册后，奖励金额将在平台内发放。`,
    "158": () => `附加的 EML 将在TrustBridgeX正式启动后在平台上发放。`,
    "159": () => `有关确切的启动日期、空投时间表和奖励金额，请稍后查看公告。`,
    "160": () => `如果您不希望参与其他活动，您可以选择不参加。通过正式活动参与赢得的 $EML 将正式发放。`,
    "161": () => `请将网络切换到以太坊主网.`,
    "162": () => `可参与人数`,
    "163": () => `福利通知`,
    "164": () => `相当于20到6000的EML`,
    "165": () => `参加其他活动`,
    "166": () => `邮件验证：15 EML`,
    "167": () => `电报验证：15 EML`,
    "168": () => `推特验证：15 EML`,
    "169": () => `将在1月份发送有关应用发布的电子邮件通知，请务必查收您的电子邮件。`,
    "170": () => `请进行Telegram登录`,
    "171": () => `邮件已发送。`,
    "172": () => `验证码不匹配。`,
    "173": () => `认证已完成。`,
    "174": () => `进行认证`,
    "175": () => `认证已完成`,
    "176": () => `请输入我们发送到您电子邮件的验证码。`,
    "177": () => `解除连接`,
    "178": () => `成功链接到Telegram。`,
    "179": () => `今天已经完全分配了200名参与者的空投名额。每天UTC时间早上6:00将提供额外的名额。感谢您的关注，请确保参与下一轮！`,
    "180": () => `第一轮活动的参与者除外`
};

export const jp = {
    "1": () => { return "最も簡単な仮想通貨取引" },
    "2": (app_name) => {
        return `${app_name}は、伝統的な取引所をバイパスし、ユーザー間取引とスマートコントラクトを介して仮想通貨取引を簡略化し、安全に行う仮想通貨のピアツーピア取引プラットフォームです。`;
    },
    "3": () => "ダウンロード",
    "4": () => "紹介動画",
    "5": (app_name) => `${app_name}とは？`,
    "6": (app_name) => {
        return `${app_name}は、伝統的な取引所をバイパスし、ユーザー間取引とスマートコントラクトを介して仮想通貨取引を簡略化し、安全に行う仮想通貨のピアツーピア取引プラットフォームです。`;
    },
    "7": (app_name) => `${app_name}を使用する理由`,
    "8": (app_name) => `${app_name}エスクローは、ユーザーが現金を預けたにもかかわらずトークンを受け取らないリスクを最小限に抑えるため、トークンをエスクローに安全に保管し、取引が確認されるとトークンを自動的に購入者のウォレットに転送することにより、トークンの別個の転送の必要性をなくしました。`,
    "9": () => '送信',
    "10": () => 'すぐに任意の場所に送信',
    "11": () => 'スワップ',
    "12": () => 'より速い取引のためにトークンポイントにスワップ',
    "13": () => '取引',
    "14": () => '誰とでも、いつでも、世界中で取引',
    "15": () => 'スピード',
    "16": () => 'どんなブロックチェーンシステムよりも速い',
    "17": () => '認証',
    "18": () => '複雑な中央集権取引所の認証システムを省略',
    "19": () => '出金',
    "20": () => 'いつでも現金と交換できます',
    "21": () => 'アプリのスクリーンショット',
    "22": () => 'ユーザーの利便性を考慮したクリーンなUI',
    "23": () => '主要な機能',
    "24": (app_name) => `${app_name}の主要な機能`,
    "25": () => '私のウォレット',
    "26": () => `登録時に自動的に作成される仮想通貨ウォレットを使用して、資産/取引の概要、資産のエクスポート、ウォレットアドレスのエクスポートなどをサポートしています。`,
    "27": () => 'トークンの販売/リスト',
    "28": () => `USDT、BNB、WETHなどのトークンを販売登録し、通貨の種類に制約を受けずに販売が可能です。`,
    "29": () => 'トークン購入リクエスト/販売リスト',
    "30": () => `リストアップされたトークンをリアルタイムのユーザー間取引で購入できます。`,
    "31": () => `ユーザー数`,
    "32": () => 'アプリのダウンロード回数',
    "33": () => '取引回数',
    "34": () => '取引手数料',
    "35": () => 'サポートされているトークン',
    "36": (app_name) => `${app_name}の取引でサポートされているトークンです。`,
    "37": () => '事前登録イベント',
    "38": () => 'メールアドレスを入力してください！',
    "39": () => '今すぐ申し込んでください！',
    "40": () => '申し込む',
    "41": () => "事前登録イベント",
    "42": () => "イベント参加要綱",
    "43": () => "以前に電子メール、ウォレットアドレス、または電話番号で申し込んだ場合、以前の申し込みは強制的にキャンセルされます。",
    "44": () => "報酬を受け取るには、リリース後に提供した電子メールでサインアップする必要があります。",
    "45": () => "連絡先情報を入力する際は、正確な処理のために国コードを含めてください。",
    "46": () => "2000人の抽選後、当選者に報酬が支払われます。",
    "47": () => "提供された番号に送信された確認コードを入力してください。",
    "48": () => "確認完了",
    "49": () => "確認取得",
    "50": () => "今すぐ申し込む",
    "51": () => "申し込み失敗",
    "52": () => "無効なウォレットアドレス",
    "53": () => "申し込み失敗",
    "54": () => "すべての情報を入力してください",
    "55": () => "申し込み失敗",
    "56": () => "携帯電話の確認を行ってください",
    "57": () => "提供された番号に送信された確認コードを入力してください",
    "58": () => "メールアドレスを入力してください",
    "59": () => "申し込み失敗",
    "60": () => "ウォレットアドレスを入力してください",
    "61": () => "申し込み失敗",
    "62": () => "すでに申し込んでいます",
    "63": () => "確認失敗",
    "64": () => "確認コードが一致しません",
    "65": () => "確認失敗",
    "66": () => "メール形式が正しくありません",
    "67": () => "イベント申し込みが完了しました",
    "68": () => "ホーム",
    "69": () => "メールアドレスを入力してください",
    "70": () => "0xで始まるウォレットアドレスを入力してください",
    "71": () => "確認用の電話番号を入力してください",
    "72": () => "認証コードが届いていないかもしれませんか？",
    "73": () => "もう一度試してください",
    // marquee
    "74": (address, amount) => `${address} ${amount} 当選者`,
    // event modal
    "75": () => `今日のイベントの数量はすべて使い果たされました`,
    "76": () => `個人情報の収集と利用に同意してください`,
    // event
    "77": () => `イベントへの参加のために、`,
    "78": () => `個人情報の収集と利用に同意`,

    // input
    "79": (label) => `あなたの${label}アカウントを記入してください（任意）`,

    // privacy
    "80": () => `- TrustBridgeX（以下「当社」または「私たち」）は個人情報の保護を重要視し、当社のアプリケーションおよびサービス（以下「サービス」または「アプリ」）を介して収集される個人情報の収集、利用、保存、開示に関する情報を提供します。このポリシーはアプリのユーザー（以下「ユーザー」または「あなた」）に適用されます。`,
    "81": () => `個人情報は以下の目的で処理される可能性があります：`,
    "82": () => `- サービスの提供および運用`,
    "83": () => `- ユーザーサポートと対応`,
    "84": () => `- アプリの改善およびユーザーエクスペリエンスの向上`,
    "85": () => `- 法的義務の遵守`,
    "86": () => `- 収集される個人情報`,
    "87": () => `私たちは以下の個人情報を収集することがあります：`,
    "88": () => `- 識別情報：名前、メールアドレス`,
    "89": () => `- デバイス情報：デバイス識別子、IPアドレス、ブラウザの種類およびバージョン`,
    "90": () => `- 位置情報：地理的位置情報（位置サービスが有効な場合）`,
    "91": () => `個人情報の収集方法`,
    "92": () => `私たちは以下の方法で個人情報を収集することがあります：`,
    "93": () => `- アプリのユーザーが直接提供した情報`,
    "94": () => `- 自動的に収集された情報：Cookieおよび類似のトラッキング技術を介して収集`,
    "95": () => `私たちはユーザーがサービスの使用を中断するまでまたは法的義務を履行するために必要な期間中、個人情報を保管します。`,
    "96": () => `個人情報の共有と提供`,
    "97": () => `私たちは次の場合にユーザーの個人情報を共有することがあります：`,
    "98": () => `- 法的要求に従って`,
    "99": () => `- ユーザーの同意がある場合`,
    "100": () => `- 当社と個人情報の処理に関する契約を締結した場合`,
    "101": () => `個人情報に関する権利`,
    "102": () => `私たちはユーザーに次の個人情報に関する権利を付与します：`,
    "103": () => `- 個人情報の閲覧および修正`,
    "104": () => `- 個人情報の削除`,
    "105": () => `- 個人情報の処理に対する同意の撤回`,
    "106": () => `- 個人情報の処理の制限`,
    "107": () => `- 個人情報の転送の制限`,
    "108": () => `- セキュリティ`,
    "109": () => `私たちはユーザーの個人情報を保護するために合理的なセキュリティ対策を講じています。ただし、インターネット通信は100%安全でない可能性があり、ユーザーが個人情報を送信する際、セキュリティを保証することはできません。`,
    "110": () => `プライバシーポリシーの変更`,
    "111": () => `当社はこのプライバシーポリシーを変更する権利を保有し、変更はこのページに掲載されます。変更がある場合はユーザーに通知されます。`,
    "112": () => `お問い合わせ`,
    "113": () => `プライバシーポリシーに関する質問、提案、苦情がある場合は、trustbridgex までお問い合わせください。このプライバシーポリシーは2023年11月07日より有効です。`,
    // Event SuccessModal
    "114": () => `おめでとうございます！`,
    "115": () => `完了しました`,
    "116": () => `TrustBridgeX プライバシーポリシー`,
    // 2023.11.16 추가
    "117": () => '電話番号の形式が一致しません。',
    "118": () => 'まずTwitterでログインしてください。',
    "119": () => 'サーバーとのリクエスト中にエラーが発生しました。もう一度やり直してください。',
    "120": () => 'Twitterログインをもう一度お試しください。',
    "121": () => '認証コードが送信されました。',
    "122": () => 'Twitterログインに成功しました。',
    "123": () => 'サポートされていないブラウザ、またはMetaMaskのインストールが検出されません。確認してください。',
    "124": () => 'EMLトークンが追加されました。',
    "125": () => 'MetaMaskにEMLトークンを追加する',
    "126": () => '追加のイベントを進行したいですか？',
    "127": () => `イベント期間`,
    "128": () => `1月22日から1月26日まで（早期終了の可能性あり）`,
    "129": () => `参加対象`,
    "130": () => `事前予約に参加したすべてのユーザー`,
    "131": () => `参加方法`,
    "132": () => `メール、Telegram、電話番号の確認後、事前登録`,
    "133": () => `ボーナスイベント：イベントフォームの記入`,
    "134": () => `イベントの特典`,
    "135": () => `$EML 5個から3000個がランダムに当選します`,
    "136": () => `特典授与日`,
    "137": () => `2024年1月までにユーザーのアプリ内ウォレットに入金されます。`,
    "138": () => `規定ガイドライン`,
    "139": () => `電話番号とメールの両方を記入する必要があります。`,
    "140": () => `進行するには、メール、電話番号、Telegramの確認が必要です。`,
    "141": () => `Pendaftaran ganda tidak diizinkan.`,
    "142": () => `Jika pendaftaran ganda dari orang yang sama dikonfirmasi, kemenangan dapat dibatalkan.`,
    "143": () => `Partisipasi terbatas hanya sekali dalam acara ini.`,
    "144": () => `Tombol pra-pemesanan akan diaktifkan hanya jika semua proses verifikasi yang diperlukan selesai. (Nonaktif jika verifikasi gagal)`,
    "145": () => `Dengan menekan tombol yang diaktifkan, partisipasi pra-pemesanan selesai.`,
    "146": () => `Manfaat akan dikreditkan ke dompet dalam aplikasi pengguna pada tanggal rilis.`,
    "148": () => `Ketika menarik EML yang diterima nanti dari TrustBridgeX, EML akan digunakan sebagai biaya. Tidak perlu deposit ETH terpisah untuk biaya.
      (Sebagian dari EML yang diterima akan digunakan sebagai biaya saat menarik.)`,
    "149": () => `Dalam kasus kesalahan pengiriman karena informasi yang salah, perusahaan tidak bertanggung jawab.`,
    "150": () => `Acara ini dapat berubah atau berakhir lebih awal tanpa pemberitahuan karena keadaan TrustBridgeX.`,
    "151": () => `TrustBridgeX mengumpulkan informasi untuk pra-pemesanan layanan, berbagai berita dan pemberitahuan acara, dan identifikasi anggota. Partisipasi dalam acara dianggap sebagai persetujuan untuk memberikan informasi.`,
    "152": () => `収集された情報はイベント終了後3か月以内に廃棄されます`,
    // Success Modal
    "153": () => `下のボタンをクリックして参加フォームを記入すると、\nボーナスイベントに参加できます。`,
    "154": () => `ボーナスイベントに参加する`,
    // 20231201 추가
    "155": () => `イベントに参加された方には、追加で $EML が送信されます。`,
    "156": () => `各フォームの記入が完了すると、ミッションポイントが獲得できます。`,
    "157": () => `ボーナスイベントに参加した後、ボーナス金額はTrustBridgeXのローンチ後に提供されたメールアドレスでプラットフォーム内に入金されます。`,
    "158": () => `追加の EML はTrustBridgeXが正式にローンチされると、プラットフォーム内で送金されます。`,
    "159": () => `正確なローンチ日、エアドロップスケジュール、および報酬額については、今後のお知らせをご確認ください。`,
    "160": () => `希望しない場合、追加のイベントに参加しないこともできます。公式イベントへの参加によって当選した $EML は正式に支払われます。`,
    "161": () => `ネットワークをEthereumメインネットに変更してください。`,
    "162": () => `参加可能な人数`,
    "163": () => `特典案内`,
    "164": () => `20 EML 〜 6000 EML相当`,
    "165": () => `追加イベント参加時`,
    "166": () => `メール認証：15 EML`,
    "167": () => `テレグラム認証：15 EML`,
    "168": () => `Twitter認証：15 EML`,
    "169": () => `1月中にアプリの発売に関するメールが送信されます。必ずメールを確認してください。`,
    "170": () => `Telegramログインを進めてください。`,
    "171": () => `メールが送信されました。`,
    "172": () => `認証コードが一致しません。`,
    "173": () => `認証が完了しました。`,
    "174": () => `認証する`,
    "175": () => `認証が完了しました`,
    "176": () => `メールに送信された認証コードを入力してください。`,
    "177": () => `接続解除`,
    "178": () => `Telegramとの連携に成功しました。`,
    "179": () => `本日、200人の参加者に対するエアドロップの割り当てが完了しました。毎日午前6時（UTC）に追加の空きが利用可能になります。ご興味をお寄せいただきありがとうございます。次のラウンドにもぜひ参加してください！`,
    "180": () => `第1ラウンドのイベント参加者は除外されます`
};

export const indonesia = {
    "1": () => { return "Perdagangan mata uang kripto paling mudah" },
    "2": (app_name) => {
        return `${app_name} adalah platform perdagangan mata uang kripto peer-to-peer yang menyederhanakan dan mengamankan transaksi mata uang kripto melalui perdagangan antar pengguna dan kontrak pintar, menghindari pertukaran tradisional.`;
    },
    "3": () => "Unduh",
    "4": () => "Video Pengenalan",
    "5": (app_name) => `${app_name} itu apa?`,
    "6": (app_name) => {
        return `${app_name} adalah platform perdagangan mata uang kripto peer-to-peer yang menyederhanakan dan mengamankan transaksi mata uang kripto melalui perdagangan antar pengguna dan kontrak pintar, menghindari pertukaran tradisional.`;
    },
    "7": (app_name) => `Mengapa Anda harus menggunakan ${app_name}?`,
    "8": (app_name) => `${app_name} Escrow menyimpan token dalam escrow saat seorang pengguna mendaftar penjualan, meminimalkan risiko deposit pembeli namun tidak menerima token. Ini juga mengotomatisasi proses transfer token, memastikan token secara otomatis ditransfer dari escrow ke dompet pembeli saat konfirmasi transaksi, menghilangkan kebutuhan transfer token terpisah.`,
    "9": () => 'Kirim',
    "10": () => 'Kirim ke lokasi yang Anda inginkan secara instan',
    "11": () => 'Tukar',
    "12": () => 'Tukar ke poin token untuk transaksi lebih cepat',
    "13": () => 'Perdagangan',
    "14": () => 'Perdagangkan dengan siapa pun, kapan saja, di seluruh dunia',
    "15": () => 'Kecepatan',
    "16": () => 'Lebih cepat dari sistem blockchain mana pun',
    "17": () => 'Otentikasi',
    "18": () => 'Otentikasi yang disederhanakan, menghilangkan sistem pertukaran terpusat yang kompleks',
    "19": () => 'Tarik Dana',
    "20": () => 'Tukar dengan uang tunai kapan pun Anda inginkan',
    "21": () => 'Tangkapan Layar Aplikasi',
    "22": () => 'UI bersih yang dirancang untuk kenyamanan pengguna',
    "23": () => 'Fitur Utama',
    "24": (app_name) => `Fitur utama dari ${app_name}`,
    "25": () => 'Dompet Saya',
    "26": () => `Dompet mata uang kripto yang dibuat secara otomatis saat pendaftaran memungkinkan tinjauan aset/transaksi, ekspor aset, ekspor alamat dompet saya, dan lainnya.`,
    "27": () => 'Penjualan/Listing Token',
    "28": () => `Daftarkan token seperti USDT, BNB, WETH untuk dijual, memungkinkan penjualan tanpa batasan jenis mata uang.`,
    "29": () => 'Permintaan Pembelian Token/Daftar Penjualan',
    "30": () => `Beli token yang terdaftar melalui perdagangan pengguna-ke-pengguna secara real-time.`,
    "31": () => `Jumlah Pengguna`,
    "32": () => 'Jumlah Unduhan Aplikasi',
    "33": () => 'Jumlah Transaksi',
    "34": () => 'Biaya Transaksi',
    "35": () => 'Token yang Didukung',
    "36": (app_name) => `Token yang didukung oleh ${app_name} untuk perdagangan.`,
    "37": () => 'Acara pra-registrasi',
    "38": () => 'Harap masukkan alamat email Anda!',
    "39": () => "Daftar sekarang juga!",
    "40": () => 'Mendaftar',
    "41": () => "Acara pra-registrasi",
    "42": () => "Pedoman Partisipasi Acara",
    "43": () => "Jika Anda telah mendaftar sebelumnya dengan email, alamat dompet, atau nomor telepon, pendaftaran sebelumnya akan dibatalkan secara paksa.",
    "44": () => "Anda harus mendaftar dengan alamat email yang telah Anda berikan setelah rilis untuk menerima hadiah.",
    "45": () => "Ketika memasukkan informasi kontak, pastikan untuk menyertakan kode negara untuk pemrosesan yang tepat.",
    "46": () => "Setelah melakukan pengundian acak 2000 orang, pemenang akan menerima hadiah mereka.",
    "47": () => "Silakan masukkan kode verifikasi yang dikirimkan ke nomor yang Anda berikan.",
    "48": () => "Verifikasi Selesai",
    "49": () => "Dapatkan Verifikasi",
    "50": () => "Mendaftar Sekarang",
    "51": () => "Pendaftaran Gagal",
    "52": () => "Alamat Dompet Tidak Valid",
    "53": () => "Pendaftaran Gagal",
    "54": () => "Harap isi semua informasi",
    "55": () => "Pendaftaran Gagal",
    "56": () => "Harap lanjutkan dengan verifikasi ponsel",
    "57": () => "Silakan masukkan kode verifikasi yang dikirimkan ke nomor yang Anda berikan",
    "58": () => "Silakan masukkan email Anda",
    "59": () => "Pendaftaran Gagal",
    "60": () => "Silakan masukkan alamat dompet Anda",
    "61": () => "Pendaftaran Gagal",
    "62": () => "Anda sudah mendaftar sebelumnya",
    "63": () => "Verifikasi Gagal",
    "64": () => "Kode Verifikasi Tidak Cocok",
    "65": () => "Verifikasi Gagal",
    "66": () => "Format Email Tidak Valid",
    "67": () => "Pendaftaran Acara Telah Selesai",
    "68": () => "Beranda",
    "69": () => "Silakan masukkan alamat email Anda",
    "70": () => "Silakan masukkan alamat dompet yang dimulai dengan 0x",
    "71": () => "Silakan masukkan nomor telepon untuk verifikasi",
    "72": () => "Apakah Anda tidak menerima kode verifikasi mungkin?",
    "73": () => "Coba lagi",
    // marquee
    "74": (address, amount) => `${address} ${amount} Pemenang`,
    // event modal
    "75": () => `Jumlah acara hari ini telah habis`,
    "76": () => `Silakan centang persetujuan pengumpulan dan penggunaan data pribadi`,
    // event
    "77": () => `Untuk mengikuti acara,`,
    "78": () => `Persetujuan pengumpulan dan penggunaan data pribadi`,

    // input
    "79": (label) => `Silakan isi akun ${label} Anda (opsional)`,

    // privacy
    "80": () => `- TrustBridgeX (selanjutnya disebut "Perusahaan" atau "kami") sangat memperhatikan perlindungan data pribadi dan memberikan informasi tentang pengumpulan, penggunaan, penyimpanan, dan pengungkapan data pribadi yang dikumpulkan melalui aplikasi dan layanan kami (selanjutnya disebut "Layanan" atau "Aplikasi"). Kebijakan ini berlaku untuk pengguna aplikasi (selanjutnya disebut "Pengguna" atau "Anda").`,
    "81": () => `Data pribadi dapat diproses untuk tujuan berikut:`,
    "82": () => `- Penyediaan dan pengoperasian layanan`,
    "83": () => `- Dukungan dan respons pengguna`,
    "84": () => `- Perbaikan aplikasi dan peningkatan pengalaman pengguna`,
    "85": () => `- Kepatuhan dengan kewajiban hukum`,
    "86": () => `- Data pribadi yang dikumpulkan`,
    "87": () => `Kami dapat mengumpulkan data pribadi berikut:`,
    "88": () => `- Informasi identifikasi: nama, alamat email`,
    "89": () => `- Informasi perangkat: pengenal perangkat, alamat IP, jenis dan versi peramban`,
    "90": () => `- Informasi lokasi: informasi lokasi geografis (saat layanan lokasi diaktifkan)`,
    "91": () => `Metode pengumpulan data pribadi`,
    "92": () => `Kami dapat mengumpulkan data pribadi melalui metode berikut:`,
    "93": () => `- Informasi yang diberikan langsung oleh pengguna aplikasi`,
    "94": () => `- Informasi yang dikumpulkan secara otomatis: dikumpulkan melalui cookie dan teknologi pelacakan serupa`,
    "95": () => `Kami menyimpan data pribadi selama jangka waktu yang diperlukan agar pengguna menghentikan penggunaan layanan atau untuk memenuhi kewajiban hukum.`,
    "96": () => `Pembagian dan penyediaan data pribadi`,
    "97": () => `Kami dapat membagikan data pribadi pengguna dalam situasi berikut:`,
    "98": () => `- Sesuai dengan persyaratan hukum`,
    "99": () => `- Dengan persetujuan pengguna`,
    "100": () => `- Ketika kami telah melakukan kontrak pemrosesan data pribadi dengan pengguna`,
    "101": () => `Hak-hak terkait data pribadi`,
    "102": () => `Kami memberikan hak-hak berikut kepada pengguna terkait data pribadi:`,
    "103": () => `- Akses dan pengubahan data pribadi`,
    "104": () => `- Penghapusan data pribadi`,
    "105": () => `- Penarikan persetujuan untuk pemrosesan data pribadi`,
    "106": () => `- Pembatasan pemrosesan data pribadi`,
    "107": () => `- Pembatasan transfer data pribadi`,
    "108": () => `- Keamanan`,
    "109": () => `Kami mengambil tindakan keamanan yang wajar untuk melindungi data pribadi pengguna. Namun, komunikasi internet mungkin tidak 100% aman, dan kami tidak dapat menjamin keamanan saat pengguna mengirimkan data pribadi.`,
    "110": () => `Perubahan Kebijakan Privasi`,
    "111": () => `Kami berhak mengubah kebijakan privasi ini, dan perubahan akan diposting di halaman ini. Pengguna akan diberitahu tentang perubahan tersebut.`,
    "112": () => `Kontak`,
    "113": () => `Jika Anda memiliki pertanyaan, saran, atau keluhan mengenai kebijakan privasi, silakan hubungi kami di trustbridgex@gmail.com. Kebijakan privasi ini berlaku mulai tanggal 07 November 2023.`,
    // Event SuccessModal
    "114": () => `Selamat!`,
    "115": () => `Selesai`,
    "116": () => `Kebijakan Privasi TrustBridgeX`,
    // 2023.11.16 추가
    "117": () => 'Format nomor telepon tidak sesuai.',
    "118": () => 'Mohon lakukan login Twitter terlebih dahulu.',
    "119": () => 'Terjadi kesalahan selama permintaan ke server. Silakan coba lagi.',
    "120": () => 'Silakan coba login Twitter kembali.',
    "121": () => 'Kode verifikasi telah dikirim.',
    "122": () => 'Login Twitter berhasil.',
    "123": () => 'Browser tidak didukung atau instalasi MetaMask tidak terdeteksi. Harap periksa.',
    "124": () => 'Token EML telah ditambahkan.',
    "125": () => 'Tambahkan token EML ke MetaMask',
    "126": () => 'Apakah Anda ingin melanjutkan dengan acara tambahan?',
    "127": () => `Periode Acara`,
    "128": () => `Dari 22 Januari hingga 26 Januari (dapat berakhir lebih awal)`,
    "129": () => `Peserta`,
    "130": () => `Semua pengguna yang berpartisipasi dalam pemesanan sebelumnya`,
    "131": () => `Cara Berpartisipasi`,
    "132": () => `Mendaftar sebelumnya setelah verifikasi email, Telegram, dan nomor telepon`,
    "133": () => `Acara Bonus: Mengisi formulir acara`,
    "134": () => `Manfaat Acara`,
    "135": () => `Dipilih secara acak antara 5 hingga 3000 $EML`,
    "136": () => `Tanggal Pemberian Manfaat`,
    "137": () => `Akan dikreditkan ke dompet pengguna di dalam aplikasi pada Januari 2024.`,
    "138": () => `Pedoman Peraturan`,
    "139": () => `Harus mengisi baik nomor telepon maupun email.`,
    "140": () => `Verifikasi email, nomor telepon, dan Telegram harus dikonfirmasi untuk melanjutkan.`,
    "141": () => `Pendaftaran ganda tidak diizinkan`,
    "142": () => `Jika terdeteksi pendaftaran ganda dengan nama yang sama, penghargaan dapat dibatalkan`,
    "143": () => `Hanya boleh berpartisipasi satu kali dalam acara ini`,
    "144": () => `Tombol pemesanan sebelumnya akan diaktifkan hanya untuk mereka yang terverifikasi sepenuhnya di UI (tidak aktif jika verifikasi gagal)`,
    "145": () => `Klik tombol yang diaktifkan untuk menyelesaikan pemesanan sebelumnya`,
    "146": () => `Manfaat akan dikirimkan ke alamat dompet yang terdaftar dalam 2 hari kerja`,
    "147": () => `Kontrak $EML: 0x03dDe9e5BB31ee40A471476e2FCcF75C67921062`,
    "148": () => `Tambahkan token dalam dompet MetaMask dan masukkan kontrak ini untuk melihat jumlah $EML yang dimiliki`,
    "149": () => `Kesalahan pengisian informasi yang menyebabkan kesalahan pengiriman tidak dapat diakui`,
    "150": () => `Acara ini dapat berubah atau berakhir lebih awal tanpa pemberitahuan terlebih dahulu atas kebijakan TrustBridgeX`,
    "151": () => `TrustBridgeX mengumpulkan informasi untuk pemesanan layanan, pengiriman berita dan pemberitahuan acara, dan identifikasi anggota. Dengan berpartisipasi dalam acara ini, dianggap telah menyetujui penyediaan informasi`,
    "152": () => `Informasi yang dikumpulkan akan dihapus dalam waktu 3 bulan setelah berakhirnya periode acara`,
    // Success Modal
    "153": () => `Klik tombol di bawah\nini dan isi formulir partisipasi\nuntuk mengikuti acara bonus.`,
    "154": () => `Ikut Serta dalam Acara Bonus`,
    // 20231201 추가
    "155": () => `Hanya untuk peserta yang berpartisipasi dalam acara, akan dikirimkan tambahan $EML.`,
    "156": () => `Setiap formulir yang diisi lengkap akan mendapatkan poin misi.`,
    "157": () => `Setelah berpartisipasi dalam acara bonus, jumlah bonus akan dikreditkan dalam platform setelah pendaftaran dengan alamat email yang diberikan setelah peluncuran TrustBridgeX.`,
    "158": () => `Tambahan EML akan dikirimkan di dalam platform setelah TrustBridgeX diluncurkan secara resmi.`,
    "159": () => `Silakan tunggu pengumuman lebih lanjut mengenai tanggal peluncuran yang tepat, jadwal airdrop, dan jumlah hadiah.`,
    "160": () => `Jika Anda tidak ingin berpartisipasi dalam acara tambahan, Anda dapat memilih untuk tidak berpartisipasi. $EML yang dimenangkan melalui partisipasi acara resmi akan dikirimkan secara resmi.`,
    "161": () => `Silakan ubah jaringan ke jaringan utama Ethereum.`,
    "162": () => `Peserta yang Tersedia`,
    "163": () => `Informasi Keuntungan`,
    "164": () => `Acara Utama: 20 EML ~ 6000 EML`,
    "165": () => `Bergabung dalam Acara Tambahan`,
    "166": () => `Verifikasi Email: 15 EML`,
    "167": () => `Verifikasi Telegram: 15 EML`,
    "168": () => `Verifikasi Twitter: 15 EML`,
    "169": () => `Pada bulan Januari, email pemberitahuan tentang peluncuran aplikasi akan dikirimkan. Pastikan untuk memeriksa email Anda.`,
    "170": () => `Silakan lanjutkan dengan login Telegram.`,
    "171": () => `Email telah terkirim.`,
    "172": () => `Kode verifikasi tidak cocok.`,
    "173": () => `Verifikasi telah selesai.`,
    "174": () => `Verifikasi`,
    "175": () => `Verifikasi selesai`,
    "176": () => `Silakan masukkan kode verifikasi yang telah kami kirimkan ke email Anda.`,
    "177": () => `Memutuskan Koneksi`,
    "178": () => `Berhasil terhubung ke Telegram.`,
    "179": () => `Hari ini alokasi airdrop untuk 200 peserta telah sepenuhnya didistribusikan. Tempat tambahan akan menjadi tersedia setiap hari pada pukul 6:00 pagi UTC. Terima kasih atas minat Anda, pastikan untuk berpartisipasi dalam putaran berikutnya!`,
    "180": () => `Peserta acara putaran pertama dikecualikan`
};



export const countryNumber = [
    {
        koCountry: '미국',
        enCountry: 'USA',
        enCountryCode: 'US',
        CountryNum: 1,
    },
    {
        koCountry: '캐나다',
        enCountry: 'Canada',
        enCountryCode: 'CA',
        CountryNum: 1,
    },
    {
        koCountry: '대한민국',
        enCountry: 'Republic of Korea',
        enCountryCode: 'KR',
        CountryNum: 82,
    },
    {
        koCountry: '대만',
        enCountry: 'Taiwan',
        enCountryCode: 'TW',
        CountryNum: 886,
    },
    {
        koCountry: '일본',
        enCountry: 'Japan',
        enCountryCode: 'JP',
        CountryNum: 81,
    },
    {
        koCountry: '중국',
        enCountry: 'China',
        enCountryCode: 'CN',
        CountryNum: 86,
    },
    {
        koCountry: '싱가포르',
        enCountry: 'Singapore',
        enCountryCode: 'SG',
        CountryNum: 65,
    },
    {
        koCountry: '홍콩',
        enCountry: 'Hongkong',
        enCountryCode: 'HK',
        CountryNum: 852,
    },
    {
        koCountry: '인도네시아',
        enCountry: 'Indonesia',
        enCountryCode: 'ID',
        CountryNum: 62,
    },
    {
        koCountry: '말레이시아',
        enCountry: 'Malaysia',
        enCountryCode: 'MY',
        CountryNum: 60,
    },
    {
        koCountry: '필리핀',
        enCountry: 'Philippines',
        enCountryCode: 'PH',
        CountryNum: 63,
    },
    {
        koCountry: '태국',
        enCountry: 'Thailand',
        enCountryCode: 'TH',
        CountryNum: 66,
    },
    {
        koCountry: '브루나이',
        enCountry: 'Brunei Darussalam',
        enCountryCode: 'BN',
        CountryNum: 673,
    },
    {
        koCountry: '베트남',
        enCountry: 'Vietnam',
        enCountryCode: 'VN',
        CountryNum: 84,
    },
    {
        koCountry: '라오스',
        enCountry: 'Laos',
        enCountryCode: 'LA',
        CountryNum: 856,
    },
    {
        koCountry: '미얀마',
        enCountry: 'Myanmar',
        enCountryCode: 'MM',
        CountryNum: 95,
    },
    {
        koCountry: '캄보디아',
        enCountry: 'Cambodia',
        enCountryCode: 'KH',
        CountryNum: 855,
    },
    {
        koCountry: '마카오',
        enCountry: 'Macau',
        enCountryCode: 'MO',
        CountryNum: 853,
    },
    {
        koCountry: '방글라데시',
        enCountry: 'Bangladesh',
        enCountryCode: 'BD',
        CountryNum: 880,
    },
    {
        koCountry: '인도',
        enCountry: 'India',
        enCountryCode: 'IN',
        CountryNum: 91,
    },
    {
        koCountry: '파키스탄',
        enCountry: 'Pakistan',
        enCountryCode: 'PK',
        CountryNum: 92,
    },
    {
        koCountry: '사우디아라비아',
        enCountry: 'Saudi Arabia',
        enCountryCode: 'SA',
        CountryNum: 966,
    },
    {
        koCountry: '아랍에미리트',
        enCountry: 'United Arab Emirates',
        enCountryCode: 'AE',
        CountryNum: 971,
    },
    {
        koCountry: '아르메니아',
        enCountry: 'Armenia',
        enCountryCode: 'AM',
        CountryNum: 374,
    },
    {
        koCountry: '아제르바이잔',
        enCountry: 'Azerbaijan',
        enCountryCode: 'AZ',
        CountryNum: 994,
    },
    {
        koCountry: '아프가니스탄',
        enCountry: 'Afghanistan',
        enCountryCode: 'AF',
        CountryNum: 93,
    },
    {
        koCountry: '알바니아',
        enCountry: 'Albania',
        enCountryCode: 'AL',
        CountryNum: 355,
    },
    {
        koCountry: '예맨',
        enCountry: 'Yemen',
        enCountryCode: 'YE',
        CountryNum: 967,
    },
    {
        koCountry: '오만',
        enCountry: 'Oman',
        enCountryCode: 'OM',
        CountryNum: 968,
    },
    {
        koCountry: '요르단',
        enCountry: 'Jordan',
        enCountryCode: 'JO',
        CountryNum: 962,
    },
    {
        koCountry: '우즈베키스탄',
        enCountry: 'Uzbekistan',
        enCountryCode: 'UZ',
        CountryNum: 998,
    },
    {
        koCountry: '우크라이나',
        enCountry: 'Ukraine',
        enCountryCode: 'UA',
        CountryNum: 380,
    },
    {
        koCountry: '이스라엘',
        enCountry: 'Israel',
        enCountryCode: 'IL',
        CountryNum: 972,
    },
    {
        koCountry: '이집트',
        enCountry: 'Egypt',
        enCountryCode: 'EG',
        CountryNum: 20,
    },
    {
        koCountry: '쿠웨이트',
        enCountry: 'Kuwait',
        enCountryCode: 'KW',
        CountryNum: 965,
    },
    {
        koCountry: '키르기스스탄',
        enCountry: 'Kyrgyzstan',
        enCountryCode: 'KG',
        CountryNum: 996,
    },
    {
        koCountry: '타지키스탄',
        enCountry: 'Tajikistan',
        enCountryCode: 'TJ',
        CountryNum: 992,
    },
    {
        koCountry: '튀르키예',
        enCountry: 'Turkey',
        enCountryCode: 'TR',
        CountryNum: 90,
    },
    {
        koCountry: '투르크메니스탄',
        enCountry: 'Turkmenistan',
        enCountryCode: 'TM',
        CountryNum: 993,
    },
    {
        koCountry: '뉴질랜드',
        enCountry: 'New Zealand',
        enCountryCode: 'NZ',
        CountryNum: 64,
    },
    {
        koCountry: '호주',
        enCountry: 'Australia',
        enCountryCode: 'AU',
        CountryNum: 61,
    },
    {
        koCountry: '파푸아뉴기니',
        enCountry: 'Papua New Guinea',
        enCountryCode: 'PG',
        CountryNum: 675,
    },
    {
        koCountry: '그리스',
        enCountry: 'Greece',
        enCountryCode: 'GR',
        CountryNum: 30,
    },
    {
        koCountry: '네덜란드',
        enCountry: 'Netherlands',
        enCountryCode: 'NL',
        CountryNum: 31,
    },
    {
        koCountry: '노르웨이',
        enCountry: 'Norway',
        enCountryCode: 'NO',
        CountryNum: 47,
    },
    {
        koCountry: '덴마크',
        enCountry: 'Denmark',
        enCountryCode: 'DK',
        CountryNum: 45,
    },
    {
        koCountry: '독일',
        enCountry: 'Germany',
        enCountryCode: 'DE',
        CountryNum: 49,
    },
    {
        koCountry: '라트비아',
        enCountry: 'Latvia',
        enCountryCode: 'LV',
        CountryNum: 371,
    },
    {
        koCountry: '러시아',
        enCountry: 'Russia',
        enCountryCode: 'RU',
        CountryNum: 7,
    },
    {
        koCountry: '레바논',
        enCountry: 'Lebanon',
        enCountryCode: 'LB',
        CountryNum: 961,
    },
    {
        koCountry: '루마니아',
        enCountry: 'Romania',
        enCountryCode: 'RO',
        CountryNum: 40,
    },
    {
        koCountry: '룩셈부르크',
        enCountry: 'Luxembourg',
        enCountryCode: 'LU',
        CountryNum: 352,
    },
    {
        koCountry: '리투아니아',
        enCountry: 'Lithuania',
        enCountryCode: 'LT',
        CountryNum: 370,
    },
    {
        koCountry: '마케도니아',
        enCountry: 'Macedonia',
        enCountryCode: 'MK',
        CountryNum: 389,
    },
    {
        koCountry: '몰타',
        enCountry: 'Malta',
        enCountryCode: 'MT',
        CountryNum: 356,
    },
    {
        koCountry: '바레인',
        enCountry: 'Bahrain',
        enCountryCode: 'BH',
        CountryNum: 973,
    },
    {
        koCountry: '벨기에',
        enCountry: 'Belgium',
        enCountryCode: 'BE',
        CountryNum: 32,
    },
    {
        koCountry: '벨라루스',
        enCountry: 'Belarus',
        enCountryCode: 'BY',
        CountryNum: 375,
    },
    {
        koCountry: '보스니아',
        enCountry: 'Bosnia',
        enCountryCode: 'BA',
        CountryNum: 387,
    },
    {
        koCountry: '불가리아',
        enCountry: 'Bulgaria',
        enCountryCode: 'BG',
        CountryNum: 359,
    },
    {
        koCountry: '세르비아',
        enCountry: 'Serbia',
        CountryNum: 381,
        enCountryCode: 'RS',
    },
    {
        koCountry: '스웨덴',
        enCountry: 'Sweden',
        CountryNum: 46,
        enCountryCode: 'SE',
    },
    {
        koCountry: '스위스',
        enCountry: 'Switzerland',
        CountryNum: 41,
        enCountryCode: 'CH',
    },
    {
        koCountry: '스페인',
        enCountry: 'Spain',
        CountryNum: 34,
        enCountryCode: 'ES',
    },
    {
        koCountry: '슬로바키아',
        enCountry: 'Slovakia',
        CountryNum: 421,
        enCountryCode: 'SK',
    },
    {
        koCountry: '슬로베니아',
        enCountry: 'Slovenia',
        CountryNum: 386,
        enCountryCode: 'SI',
    },
    {
        koCountry: '아일랜드',
        enCountry: 'Ireland',
        CountryNum: 353,
        enCountryCode: 'IE',
    },
    {
        koCountry: '에스토니아',
        enCountry: 'Estonia',
        CountryNum: 372,
        enCountryCode: 'EE',
    },
    {
        koCountry: '영국',
        enCountry: 'United Kingdom',
        CountryNum: 44,
        enCountryCode: 'GB',
    },
    {
        koCountry: '오스트리아',
        enCountry: 'Austria',
        CountryNum: 43,
        enCountryCode: 'AT',
    },
    {
        koCountry: '이탈리아',
        enCountry: 'Italy',
        CountryNum: 39,
        enCountryCode: 'IT',
    },
    {
        koCountry: '체코',
        enCountry: 'Czech Republic',
        CountryNum: 420,
        enCountryCode: 'CZ',
    },
    {
        koCountry: '크로아티아',
        enCountry: 'Croatia',
        CountryNum: 385,
        enCountryCode: 'HR',
    },
    {
        koCountry: '키프로스',
        enCountry: 'Cyprus',
        CountryNum: 357,
        enCountryCode: 'CY',
    },
    {
        koCountry: '포르투갈',
        enCountry: 'Portugal',
        CountryNum: 351,
        enCountryCode: 'PT',
    },
    {
        koCountry: '폴란드',
        enCountry: 'Poland',
        CountryNum: 48,
        enCountryCode: 'PL',
    },
    {
        koCountry: '프랑스',
        enCountry: 'France',
        CountryNum: 33,
        enCountryCode: 'FR',
    },
    {
        koCountry: '핀란드',
        enCountry: 'Finland',
        CountryNum: 358,
        enCountryCode: 'FI',
    },
    {
        koCountry: '헝가리',
        enCountry: 'Hungary',
        CountryNum: 36,
        enCountryCode: 'HU',
    },
];
