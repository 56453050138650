import React, { useEffect, useState } from "react";
import { Stack, Button, Dropdown } from "react-bootstrap";
import { ASSETS } from "../../asset";
import { useRecoilState, useRecoilValue } from "recoil";
import { language } from "../../recoil/recoil";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


const Header = ({ event = false }) => {
    const [lang, setLang] = useRecoilState(language);
    const history = useHistory()
    const langList = [
        {
            key: "en",
            text: "English",
            icon: ASSETS.en
        },
        {
            key: "ko",
            text: "한국어",
            icon: ASSETS.ko
        },
        {
            key: "cn",
            text: "中文",
            icon: ASSETS.cn
        },
        {
            key: "jp",
            text: "日本語",
            icon: ASSETS.jp
        },
        {
            key: "indonesia",
            text: "Bahasa Indonesia",
            icon: ASSETS.indonesia
        }
    ]

    const sideMenuList = [
        {
            title: "Home",
            href: '#',
            class: 'scroll-to-target'
        },
        {
            title: "About",
            href: '#about',
            class: 'page-scroll'
        },
        {
            title: "features",
            href: '#',
            class: 'scroll-to-target'
        },
        {
            title: "Process",
            href: '#',
            class: 'scroll-to-target'
        },
        {
            title: "Event",
            href: '#',
            class: 'scroll-to-target'
        },
    ]

    const [filterLangData, setFilterLangData] = useState(langList.filter(item => item.key === lang)[0]);
    const [sideMenu, setSideMenu] = useState(false);

    // ERROR: 20231023 Maximum Update Depth Exceeded 
    // FIX(member_hoo): 20231013 delete langList for deps
    useEffect(() => {
        setFilterLangData(langList.filter(item => item.key === lang)[0]);
    }, [lang])

    const changeLanguage = (item) => {
        if (!item.key) return setLang('ko');

        setLang(item.key);
        setFilterLangData(item);
        localStorage.setItem('lang', item.key);
    }

    const togleMenu = () => {
        setSideMenu(!sideMenu);
    }

    return (
        <>
            <header className="header">
                <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img
                                src={ASSETS.main_logo_full}
                                alt="logo"
                                style={{ "width": '7.5rem', 'height': 'auto' }}
                            />
                        </a>
                        {
                            !event &&
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={togleMenu}
                            >
                                <span className="ti-menu"></span>
                            </button>
                        }
                        {
                            event &&
                            <Dropdown className="navbar-toggler">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <img src={filterLangData.icon} className="country_img" />
                                    <p>{filterLangData.text}</p>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        langList?.map(item => (
                                            <Dropdown.Item
                                                key={item.key}
                                                onClick={() => changeLanguage(item)}
                                                className="droplist_style"
                                            >
                                                <img src={item.icon} className="country_img" />
                                                <p>{item.text}</p>
                                            </Dropdown.Item>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <div className={`collapse navbar-collapse h-auto ${sideMenu && 'show'}`} id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto menu">
                                {
                                    !event &&
                                    <>
                                        <li>
                                            <a href="#" className="scroll-to-target" onClick={togleMenu}>
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#about" className="page-scroll" onClick={togleMenu}>
                                                About
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#features" className="page-scroll" onClick={togleMenu}>
                                                Features
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#screenshots" className="page-scroll">
                                                Screenshots
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href="#process" className="page-scroll" onClick={togleMenu}>
                                                Process
                                            </a>
                                        </li>
                                        {/* TODO : EventPage */}
                                        <li className="page-scroll" onClick={() => {
                                            history.push('/event')
                                            togleMenu();
                                        }}>
                                            {/* <a href="javascript:void(0);" className="page-scroll" onClick={() => {
                                                history.push('/event')
                                                togleMenu();
                                            }}> */}
                                            Event
                                            {/* </a> */}
                                        </li>
                                    </>
                                }
                                {
                                    event &&
                                    <>

                                    </>
                                }
                                <Dropdown >
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <img src={filterLangData.icon} className="country_img" />
                                        <p>{filterLangData.text}</p>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            langList?.map(item => (
                                                <Dropdown.Item
                                                    key={item.key}
                                                    onClick={() => changeLanguage(item)}
                                                    className="droplist_style"
                                                >
                                                    <img src={item.icon} className="country_img" />
                                                    <p>{item.text}</p>
                                                </Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header >
        </>
    );
};

export default Header;