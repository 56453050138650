import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import styles from './privacyPolicy.module.css';
import { ASSETS } from '../../asset';
import PolicyContent from '../../meta/policy';
import { useRecoilValue } from 'recoil';
import { translatedText } from '../../recoil/recoil';

const PolicyModal = ({
    isOpen,
    onClose
}) => {

    const textData = useRecoilValue(translatedText);

    return (
        <AnimatePresence>
            {
                isOpen &&
                <motion.div
                    className={styles.modal_container}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    <div>
                        <div className={styles.header}>
                            <div></div>
                            <h1>{textData['116']()}</h1>
                            <div>
                                <button onClick={onClose}>
                                    <img src={ASSETS.close_bold} />
                                </button>
                            </div>
                        </div>
                        <div>
                            <PolicyContent />
                        </div>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default PolicyModal