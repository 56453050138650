import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useHistory, useLocation } from "react-router-dom";

const RouteTracker = () => {
    const location = useLocation();
    const [init, setInit] = useState(false);
    const tracking_id = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;


    useEffect(() => {
        ReactGA?.initialize(tracking_id, {
            debug: true
        });

        setInit(true);
    }, [])

    useEffect(() => {
        if (init) {
            ReactGA.send({
                hitType: 'pageview',
                location: location.pathname,
                title: location.pathname
            })
            // ReactGA.event({
            //     category: "your category",
            //     action: "your action",
            //     label: "your label", // optional
            //     value: 99, // optional, must be a number
            //     nonInteraction: true, // optional, true/false
            //     transport: "xhr", // optional, beacon/xhr/image
            // });
        }
    }, [location, init])

    return (<></>)
}

export default RouteTracker;

