import React, { useEffect, useMemo, useState } from 'react'
import styles from './privacyPolicy.module.css'
import PolicyModal from './PolicyModal'
import { useRecoilValue } from 'recoil'
import { translatedText } from '../../recoil/recoil'

const PrivacyPolicy = React.memo(({
    check,
    setCheck
}) => {

    const [policyModalState, setPolicyModalState] = useState(false);
    const textData = useRecoilValue(translatedText);

    return (
        <div className={styles.container}>
            <PolicyModal
                isOpen={policyModalState}
                onClose={() => setPolicyModalState(false)}
            />
            <input type='checkbox'
                checked={check}
                onChange={() => {
                    setCheck(!check);
                }}
            />
            <p>{textData['77']()} <span onClick={() => setPolicyModalState(true)}>{textData['78']()}</span></p>
        </div>
    )
})

export default PrivacyPolicy